export const styles = {
  options: {
    arrowColor: '#e3ffeb',
    primaryColor: '#02214f',
  },
  tooltip: {
    border: '1px solid #02214f',
    padding: '0',
    fontSize: '12px',
  },
  tooltipFooter: {
    padding: '5px 5px',
    marginTop: '2px',
    backgroundColor: '#02214f',
  },
  buttonClose: {
    border: '1px solid white',
  },
  buttonBack: {
    border: '1px solid white',
    color: 'white',
    borderRadius: '5px',
  },
};

import { Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { FC } from 'react';
import ViewPermission from '../ViewPermission/ViewPermission';
import './OverlayLoader.less';

type Props = {
  showChildren?: boolean;
  children?: any;
  isLoading?: boolean;
  loadingMessage?: string;
};

const OverlayLoader: FC<Props> = ({ isLoading, loadingMessage, children, showChildren }) => {
  return (
    <div className={`overlay-loader ${loadingMessage ? 'has-message' : ''}`}>
      <ViewPermission isVisible={isLoading}>
        <Spin />
        <ViewPermission isVisible={Boolean(loadingMessage)}>
          <Title level={5} className="title">
            {loadingMessage}
          </Title>
        </ViewPermission>
      </ViewPermission>
      <ViewPermission isVisible={showChildren}>
        <div className={isLoading ? 'is-loading' : null}>{children}</div>
      </ViewPermission>
    </div>
  );
};

// Default Props
OverlayLoader.defaultProps = {
  showChildren: true, // control child component visibility
  isLoading: false,
};

export default OverlayLoader;

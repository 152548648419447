
// Used to convert benefitsBitsLogic
export const benefitsBits = [
  { value: 1, bitPower: 1 },
  { value: 2, bitPower: 2 },
  { value: 3, bitPower: 4 },
  { value: 4, bitPower: 8 },
  { value: 5, bitPower: 16 },
  { value: 6, bitPower: 32 },
  { value: 7, bitPower: 64 },
  { value: 8, bitPower: 128 },
];
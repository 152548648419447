export const interest = [
  { 'value': 1, 'label': '0% - 0.49%' },
  { 'value': 2, 'label': '0.5% - 0.99%' },
  { 'value': 3, 'label': '1% - 1.49%' },
  { 'value': 4, 'label': '1.5% - 1.99%' },
  { 'value': 5, 'label': '2% - 2.49%' },
  { 'value': 6, 'label': '2.5% - 2.99%' },
  { 'value': 7, 'label': '3% - 3.49%' },
  { 'value': 8, 'label': '3.5% - 3.99%' },
  { 'value': 9, 'label': '4% - 4.49%' },
  { 'value': 10, 'label': '4.5% - 4.99%' },
  { 'value': 11, 'label': '5% - 5.49%' },
  { 'value': 12, 'label': '5.5% - 5.99%' },
  { 'value': 13, 'label': '6% - 6.49%' },
  { 'value': 14, 'label': '6.5% - 6.99%' },
  { 'value': 15, 'label': '7% - 7.49%' },
  { 'value': 16, 'label': '7.5% - 7.99%' },
  { 'value': 17, 'label': '8% - 8.49%' },
  { 'value': 18, 'label': '8.5% - 8.99%' },
  { 'value': 19, 'label': '9% - 9.49%' },
  { 'value': 20, 'label': '9.5% - 9.99%' },
  { 'value': 21, 'label': '10%+' },
]
export const industry = [
  {
    value: 1,
    label: 'Agriculture, Food, & Natural Resources',
    children: [
      {
        value: 1,
        label: 'Agricultural Equipment Operator',
        parent: 1,
      },
      {
        value: 2,
        label: 'Agricultural Inspectors',
        parent: 1,
      },
      {
        value: 3,
        label: 'Agricultural Science Teachers, Postsecondary',
        parent: 1,
      },
      {
        value: 4,
        label: 'Animal Breeders',
        parent: 1,
      },
      {
        value: 5,
        label: 'Animal Scientists',
        parent: 1,
      },
      {
        value: 6,
        label: 'Butchers and Meat Cutters',
        parent: 1,
      },
      {
        value: 7,
        label: 'Buyers and Purchasing Agents, Farm Products',
        parent: 1,
      },
      {
        value: 8,
        label: 'Cartographers and Photogrammetrists',
        parent: 1,
      },
      {
        value: 9,
        label: 'Commercial Divers',
        parent: 1,
      },
      {
        value: 10,
        label: 'Continuous Mining Machine Operators',
        parent: 1,
      },
      {
        value: 11,
        label: 'Environmental Enginneering Technicians',
        parent: 1,
      },
      {
        value: 12,
        label: 'Environmental Engineers',
        parent: 1,
      },
      {
        value: 13,
        label: 'Environmental Science and Protection Technicians, including Health',
        parent: 1,
      },
      {
        value: 14,
        label: 'Explosive Workers, Blasters',
        parent: 1,
      },
      {
        value: 15,
        label: 'Fallers',
        parent: 1,
      },
      {
        value: 16,
        label: 'Farm and Home Management Advisors',
        parent: 1,
      },
      {
        value: 17,
        label: 'Farm Equipment Mechanics and Service Technicians',
        parent: 1,
      },
      {
        value: 18,
        label: 'Farmworkers and Laborers',
        parent: 1,
      },
      {
        value: 19,
        label: 'Financial Managers, Branch or Department',
        parent: 1,
      },
      {
        value: 20,
        label: 'First-Line Supervisors',
        parent: 1,
      },
      {
        value: 21,
        label: 'Fish and Game Wardens',
        parent: 1,
      },
      {
        value: 22,
        label: 'Fishers and Related Fishing Workers',
        parent: 1,
      },
      {
        value: 23,
        label: 'Food and Tobacco ',
        parent: 1,
      },
      {
        value: 24,
        label: 'Food Scientists and Conservation Technicians',
        parent: 1,
      },
      {
        value: 25,
        label: 'Forest and Conservation Workers',
        parent: 1,
      },
      {
        value: 26,
        label: 'Foresters',
        parent: 1,
      },
      {
        value: 27,
        label: 'Gas Compressor and Gas Pumping Station Operators',
        parent: 1,
      },
      {
        value: 28,
        label: 'Gas Plant Operators',
        parent: 1,
      },
      {
        value: 29,
        label: 'Extraction Workers- Helpers',
        parent: 1,
      },
      {
        value: 30,
        label: 'Loading Machine Operators',
        parent: 1,
      },
      {
        value: 31,
        label: 'Log Equipment Operators',
        parent: 1,
      },
      {
        value: 32,
        label: 'Meat, Poultry, and Fish Cutters and Trimmers',
        parent: 1,
      },
      {
        value: 33,
        label: 'Mine Cutting and Channeling Machine Operators',
        parent: 1,
      },
      {
        value: 34,
        label: 'Natural Sciences Managers',
        parent: 1,
      },
      {
        value: 35,
        label: 'Nonfarm Animal Caretakers',
        parent: 1,
      },
      {
        value: 36,
        label: 'Nursery Workers',
        parent: 1,
      },
      {
        value: 37,
        label: 'Occupational Health and Safety Specialists',
        parent: 1,
      },
      {
        value: 38,
        label: 'Pest Control Workers',
        parent: 1,
      },
      {
        value: 39,
        label: 'Recycling Coordinators',
        parent: 1,
      },
      {
        value: 40,
        label: 'Roofing Technicia',
        parent: 1,
      },
      {
        value: 41,
        label: 'Veterinarians',
        parent: 1,
      },
      {
        value: 42,
        label: 'Veterinary Assistant and Laboratory Animal Caretakers',
        parent: 1,
      },
      {
        value: 43,
        label: 'Veterinary Technologists and Technicians',
        parent: 1,
      },
      {
        value: 44,
        label: 'Water and Wastewater Treatment Plant and System Operators',
        parent: 1,
      },
      {
        value: 45,
        label: 'Zoologists',
        parent: 1,
      },
      {
        value: 46,
        label: 'OTHER',
        parent: 1,
      },
    ],
  },
  {
    value: 2,
    label: 'Architecture and Construction',
    children: [
      {
        value: 47,
        label: 'Architects',
        parent: 2,
      },
      {
        value: 48,
        label: 'Brickmasons and Blockmasons',
        parent: 2,
      },
      {
        value: 49,
        label: 'Carpenters',
        parent: 2,
      },
      {
        value: 50,
        label: 'Carpet Installers',
        parent: 2,
      },
      {
        value: 51,
        label: 'Cement and Concrete Finishers',
        parent: 2,
      },
      {
        value: 52,
        label: 'Civil Engineering Technicians',
        parent: 2,
      },
      {
        value: 53,
        label: 'Civil Engineers',
        parent: 2,
      },
      {
        value: 54,
        label: 'Construction Laborers',
        parent: 2,
      },
      {
        value: 55,
        label: 'Construction Managers',
        parent: 2,
      },
      {
        value: 56,
        label: 'Cost Estimators',
        parent: 2,
      },
      {
        value: 57,
        label: 'Crane and Tower Operators',
        parent: 2,
      },
      {
        value: 58,
        label: 'Dredge Operators',
        parent: 2,
      },
      {
        value: 59,
        label: 'Drywall and Ceiling Tile Installers',
        parent: 2,
      },
      {
        value: 60,
        label: 'Electricians',
        parent: 2,
      },
      {
        value: 61,
        label: 'Elevator Installers and Repairers',
        parent: 2,
      },
      {
        value: 62,
        label: 'Excavating Specialists',
        parent: 2,
      },
      {
        value: 63,
        label: 'Heating and Air Conditioning Mechanics, Installers',
        parent: 2,
      },
      {
        value: 64,
        label: 'General Construction Helpers',
        parent: 2,
      },
      {
        value: 65,
        label: 'Insulation Workers, Floor, Ceiling, and Wall',
        parent: 2,
      },
      {
        value: 66,
        label: 'Interior Designers',
        parent: 2,
      },
      {
        value: 67,
        label: 'Landscape Architects',
        parent: 2,
      },
      {
        value: 68,
        label: 'Landscaping and Groundskeeping Workers',
        parent: 2,
      },
      {
        value: 69,
        label: 'Painters',
        parent: 2,
      },
      {
        value: 70,
        label: 'Pipelayers',
        parent: 2,
      },
      {
        value: 71,
        label: 'Plumbers',
        parent: 2,
      },
      {
        value: 72,
        label: 'Roofers',
        parent: 2,
      },
      {
        value: 73,
        label: 'Sheet Metal Workers',
        parent: 2,
      },
      {
        value: 74,
        label: 'Solar Energy Installation Manager',
        parent: 2,
      },
      {
        value: 75,
        label: 'Stonemasons',
        parent: 2,
      },
      {
        value: 76,
        label: 'Surveyors',
        parent: 2,
      },
      {
        value: 77,
        label: 'Tile and Marble Setters',
        parent: 2,
      },
      {
        value: 78,
        label: 'OTHER',
        parent: 2,
      },
    ],
  },
  {
    value: 3,
    label: 'Arts, Audio, Video Technology and Communications',
    children: [
      {
        value: 79,
        label: 'Actors',
        parent: 3,
      },
      {
        value: 80,
        label: 'Art, Drama, & Music Teacher',
        parent: 3,
      },
      {
        value: 81,
        label: 'Audio and Video Equipment Technicians',
        parent: 3,
      },
      {
        value: 82,
        label: 'Broadcast News Analysts',
        parent: 3,
      },
      {
        value: 83,
        label: 'Broadcast Technicians',
        parent: 3,
      },
      {
        value: 84,
        label: 'Camera and Photographic Equipment Repairers',
        parent: 3,
      },
      {
        value: 85,
        label: 'Camera Operators',
        parent: 3,
      },
      {
        value: 86,
        label: 'Choreographers',
        parent: 3,
      },
      {
        value: 87,
        label: 'Commericial and Industrial Designers',
        parent: 3,
      },
      {
        value: 88,
        label: 'Copy Writers',
        parent: 3,
      },
      {
        value: 89,
        label: 'Craft Artists',
        parent: 3,
      },
      {
        value: 90,
        label: 'Dancers',
        parent: 3,
      },
      {
        value: 91,
        label: 'Directors',
        parent: 3,
      },
      {
        value: 92,
        label: 'Editors',
        parent: 3,
      },
      {
        value: 93,
        label: 'Film and Video Editors',
        parent: 3,
      },
      {
        value: 94,
        label: 'Graphic Designers',
        parent: 3,
      },
      {
        value: 95,
        label: 'Multimedia Artists and Animators',
        parent: 3,
      },
      {
        value: 96,
        label: 'Music Directors and Composers',
        parent: 3,
      },
      {
        value: 97,
        label: 'Musicians and Singers',
        parent: 3,
      },
      {
        value: 98,
        label: 'Photographers',
        parent: 3,
      },
      {
        value: 99,
        label: 'Creative Writers',
        parent: 3,
      },
      {
        value: 100,
        label: 'Producers and Directors',
        parent: 3,
      },
      {
        value: 101,
        label: 'Program Directors',
        parent: 3,
      },
      {
        value: 102,
        label: 'Radio and Television Announcers',
        parent: 3,
      },
      {
        value: 103,
        label: 'Radio Operators',
        parent: 3,
      },
      {
        value: 104,
        label: 'Reporters and Correspondents',
        parent: 3,
      },
      {
        value: 105,
        label: 'Sound Engineering Technicians',
        parent: 3,
      },
      {
        value: 106,
        label: 'Talent Directors',
        parent: 3,
      },
      {
        value: 107,
        label: 'Technicial Directors/ Managers',
        parent: 3,
      },
      {
        value: 108,
        label: 'Telecommunications Line Installers and Repairers',
        parent: 3,
      },
      {
        value: 109,
        label: 'Writers and Authors',
        parent: 3,
      },
      {
        value: 110,
        label: 'OTHER',
        parent: 3,
      },
    ],
  },
  {
    value: 4,
    label: 'Business Management and Adminstration',
    children: [
      {
        value: 111,
        label: 'Accountants',
        parent: 4,
      },
      {
        value: 112,
        label: 'Auditors',
        parent: 4,
      },
      {
        value: 113,
        label: 'Administrative Services Managers',
        parent: 4,
      },
      {
        value: 114,
        label: 'Advertising and Promotions Manager',
        parent: 4,
      },
      {
        value: 115,
        label: 'Advertising Sales Agents',
        parent: 4,
      },
      {
        value: 116,
        label: 'Agent and Business Managers: Artists, Performers, and Athletes',
        parent: 4,
      },
      {
        value: 117,
        label: 'Billing, Cost, & Rate Clerks',
        parent: 4,
      },
      {
        value: 118,
        label: 'Biofuels Production Manager',
        parent: 4,
      },
      {
        value: 119,
        label: 'Bookkeeping, Accounting, and Auditing Clerks',
        parent: 4,
      },
      {
        value: 120,
        label: 'Budget Analysts',
        parent: 4,
      },
      {
        value: 121,
        label: 'Chief Executives',
        parent: 4,
      },
      {
        value: 122,
        label: 'Chief Sustainability Officer',
        parent: 4,
      },
      {
        value: 123,
        label: 'Compliance Managers',
        parent: 4,
      },
      {
        value: 124,
        label: 'Computer Operators',
        parent: 4,
      },
      {
        value: 125,
        label: 'Correspondence Clerks',
        parent: 4,
      },
      {
        value: 126,
        label: 'Couriers and Messengers',
        parent: 4,
      },
      {
        value: 127,
        label: 'Court Reporters',
        parent: 4,
      },
      {
        value: 128,
        label: 'Data Entry Keyers',
        parent: 4,
      },
      {
        value: 129,
        label: 'Driver/Sales Workers',
        parent: 4,
      },
      {
        value: 130,
        label: 'Energy Auditors',
        parent: 4,
      },
      {
        value: 131,
        label: 'Executive Secretaries and Administrative Assistants',
        parent: 4,
      },
      {
        value: 132,
        label: 'File Clerks',
        parent: 4,
      },
      {
        value: 133,
        label: 'General and Operations Manager',
        parent: 4,
      },
      {
        value: 134,
        label: 'Human Resources Assistant',
        parent: 4,
      },
      {
        value: 135,
        label: 'Legal Secretaries',
        parent: 4,
      },
      {
        value: 136,
        label: 'Mail Clerks and Mail Machine Operators',
        parent: 4,
      },
      {
        value: 137,
        label: 'Management Analysts',
        parent: 4,
      },
      {
        value: 138,
        label: 'Marketing Managers',
        parent: 4,
      },
      {
        value: 139,
        label: 'Medical & Health Services Manager',
        parent: 4,
      },
      {
        value: 140,
        label: 'Medical Secretaries',
        parent: 4,
      },
      {
        value: 141,
        label: 'Operations Research Analyst',
        parent: 4,
      },
      {
        value: 142,
        label: 'Payroll and Timekeeping Clerks',
        parent: 4,
      },
      {
        value: 143,
        label: 'Public Relations and Fundraising Managers',
        parent: 4,
      },
      {
        value: 144,
        label: 'Public Relations Specialists',
        parent: 4,
      },
      {
        value: 145,
        label: 'Purchasing Managers',
        parent: 4,
      },
      {
        value: 146,
        label: 'Receptionists and Information Clerks',
        parent: 4,
      },
      {
        value: 147,
        label: 'Sales Managers',
        parent: 4,
      },
      {
        value: 148,
        label: 'Statement Clerks',
        parent: 4,
      },
      {
        value: 149,
        label: 'Telephone Operators',
        parent: 4,
      },
      {
        value: 150,
        label: 'Treasurers and Controllers',
        parent: 4,
      },
      {
        value: 151,
        label: 'Word Processors and Typists',
        parent: 4,
      },
      {
        value: 152,
        label: 'OTHER',
        parent: 4,
      },
    ],
  },
  {
    value: 5,
    label: 'Education and Training',
    children: [
      {
        value: 153,
        label: 'Teacher',
        parent: 5,
      },
      {
        value: 154,
        label: 'Coaches and Scouts',
        parent: 5,
      },
      {
        value: 155,
        label: 'Clinicial, Counseling, and School Psychologists',
        parent: 5,
      },
      {
        value: 156,
        label: 'Curators',
        parent: 5,
      },
      {
        value: 157,
        label: 'Education Administrators',
        parent: 5,
      },
      {
        value: 158,
        label: 'Educational, Guidance, School, & Vocational Counselors',
        parent: 5,
      },
      {
        value: 159,
        label: 'Fitness and Wellness Coordinators',
        parent: 5,
      },
      {
        value: 160,
        label: 'Graduate Teaching Assistants',
        parent: 5,
      },
      {
        value: 161,
        label: 'Library Technicians',
        parent: 5,
      },
      {
        value: 162,
        label: 'Regulatory Affairs Specialists',
        parent: 5,
      },
      {
        value: 163,
        label: 'School Psychologists',
        parent: 5,
      },
      {
        value: 164,
        label: 'Teacher Assistants',
        parent: 5,
      },
      {
        value: 165,
        label: 'Tutors',
        parent: 5,
      },
      {
        value: 166,
        label: 'OTHER',
        parent: 5,
      },
    ],
  },
  {
    value: 6,
    label: 'Finance',
    children: [
      {
        value: 167,
        label: 'Actuaries',
        parent: 6,
      },
      {
        value: 168,
        label: 'Bill and Account Collectors',
        parent: 6,
      },
      {
        value: 169,
        label: 'Brokerage Clerks',
        parent: 6,
      },
      {
        value: 170,
        label: 'Claim Adjusters, Examiners,and Investigators',
        parent: 6,
      },
      {
        value: 171,
        label: 'Claim Examiners, Property and Casualty',
        parent: 6,
      },
      {
        value: 172,
        label: 'Credit Analysts',
        parent: 6,
      },
      {
        value: 173,
        label: 'Credit Authorizers',
        parent: 6,
      },
      {
        value: 174,
        label: 'Credit Checkers',
        parent: 6,
      },
      {
        value: 175,
        label: 'Energy Brokers',
        parent: 6,
      },
      {
        value: 176,
        label: 'Financial Analysts',
        parent: 6,
      },
      {
        value: 177,
        label: 'Financial Associate',
        parent: 6,
      },
      {
        value: 178,
        label: 'Financial Quantitative Analysts',
        parent: 6,
      },
      {
        value: 179,
        label: 'Fraud Examiners, Investigators, and Analysts',
        parent: 6,
      },
      {
        value: 180,
        label: 'Insurance Adjusters, Examiners, and Investigators',
        parent: 6,
      },
      {
        value: 181,
        label: 'Insurance Appraisers, Auto Damage',
        parent: 6,
      },
      {
        value: 182,
        label: 'Insurance Claims and Policy Processing Clerks',
        parent: 6,
      },
      {
        value: 183,
        label: 'Insurance Claims Clerks',
        parent: 6,
      },
      {
        value: 184,
        label: 'Insurance Sales Agent',
        parent: 6,
      },
      {
        value: 185,
        label: 'Insurance Underwriters',
        parent: 6,
      },
      {
        value: 186,
        label: 'Investment Fund Managers',
        parent: 6,
      },
      {
        value: 187,
        label: 'Investment Underwriters',
        parent: 6,
      },
      {
        value: 188,
        label: 'Loan Interviewers and Clerks',
        parent: 6,
      },
      {
        value: 189,
        label: 'Loan Officers',
        parent: 6,
      },
      {
        value: 190,
        label: 'Personal Financial Advisors',
        parent: 6,
      },
      {
        value: 191,
        label: 'Proofreaders and Copy Markers',
        parent: 6,
      },
      {
        value: 192,
        label: 'Risk Management Specialists',
        parent: 6,
      },
      {
        value: 193,
        label: 'Sales Agents, Financial Services',
        parent: 6,
      },
      {
        value: 194,
        label: 'Sales Agents, Securities and Commodities',
        parent: 6,
      },
      {
        value: 195,
        label: 'Securities and Commodities Traders',
        parent: 6,
      },
      {
        value: 196,
        label: 'Securities, Commodities, and Financial Services Sales Agents',
        parent: 6,
      },
      {
        value: 197,
        label: 'Tax Preparers',
        parent: 6,
      },
      {
        value: 198,
        label: 'Tellers',
        parent: 6,
      },
      {
        value: 199,
        label: 'Title Examiners, Abstrators, and Searchers',
        parent: 6,
      },
      {
        value: 200,
        label: 'OTHER',
        parent: 6,
      },
    ],
  },
  {
    value: 7,
    label: 'Government and Public Adminstration',
    children: [
      {
        value: 201,
        label: 'Air Crew Members',
        parent: 7,
      },
      {
        value: 202,
        label: 'Air Crew Officers',
        parent: 7,
      },
      {
        value: 203,
        label: 'Aircraft Launch and Recovery Officers',
        parent: 7,
      },
      {
        value: 204,
        label: 'Aircraft Launch and Recovery Specialist',
        parent: 7,
      },
      {
        value: 205,
        label: 'Appraisers and Assessors of Real Estate',
        parent: 7,
      },
      {
        value: 206,
        label: 'Armored Assault Vehicle Crew Members',
        parent: 7,
      },
      {
        value: 207,
        label: 'Armored Assault Vehicle Officers',
        parent: 7,
      },
      {
        value: 208,
        label: 'Artillery and Missile Crew Members',
        parent: 7,
      },
      {
        value: 209,
        label: 'Artillery and Missile Officers',
        parent: 7,
      },
      {
        value: 210,
        label: 'Assessors',
        parent: 7,
      },
      {
        value: 211,
        label: 'City and Regional Planning Aides',
        parent: 7,
      },
      {
        value: 212,
        label: 'Climate Change Analysts',
        parent: 7,
      },
      {
        value: 213,
        label: 'Command and Control Center Officers',
        parent: 7,
      },
      {
        value: 214,
        label: 'Command and Control Center Specialists',
        parent: 7,
      },
      {
        value: 215,
        label: 'Compliance Officers',
        parent: 7,
      },
      {
        value: 216,
        label: 'Coroners',
        parent: 7,
      },
      {
        value: 217,
        label: 'Court Clerks',
        parent: 7,
      },
      {
        value: 218,
        label: 'Court, Municipal, and License Clerks',
        parent: 7,
      },
      {
        value: 219,
        label: 'Detectives and Criminal Investigators',
        parent: 7,
      },
      {
        value: 220,
        label: 'Economists',
        parent: 7,
      },
      {
        value: 221,
        label: 'Equal Opportunity Representatives and Officers',
        parent: 7,
      },
      {
        value: 222,
        label: 'Financial Examiners',
        parent: 7,
      },
      {
        value: 223,
        label: 'Government Property Inspectors and Investigators',
        parent: 7,
      },
      {
        value: 224,
        label: 'Infantry',
        parent: 7,
      },
      {
        value: 225,
        label: 'Infrantry Officers',
        parent: 7,
      },
      {
        value: 226,
        label: 'Legislators',
        parent: 7,
      },
      {
        value: 227,
        label: 'License Clerks',
        parent: 7,
      },
      {
        value: 228,
        label: 'Licensing Examiners and Inspectors',
        parent: 7,
      },
      {
        value: 229,
        label: 'Meter Readers',
        parent: 7,
      },
      {
        value: 230,
        label: 'Municipal Clerks',
        parent: 7,
      },
      {
        value: 231,
        label: 'Postal Service Clerks',
        parent: 7,
      },
      {
        value: 232,
        label: 'Postal Service Mail Carriers',
        parent: 7,
      },
      {
        value: 233,
        label: 'Postmasters and Mail Superintendents',
        parent: 7,
      },
      {
        value: 234,
        label: 'Radar and Sonar Technicians',
        parent: 7,
      },
      {
        value: 235,
        label: 'Statistical Assistants',
        parent: 7,
      },
      {
        value: 236,
        label: 'Tax Examiners and Collectors',
        parent: 7,
      },
      {
        value: 237,
        label: 'Transportation Inspectors',
        parent: 7,
      },
      {
        value: 238,
        label: 'Urban and Regional Planners',
        parent: 7,
      },
      {
        value: 239,
        label: 'OTHER',
        parent: 7,
      },
    ],
  },
  {
    value: 8,
    label: 'Health Sciences',
    children: [
      {
        value: 240,
        label: 'Acupuncturists',
        parent: 8,
      },
      {
        value: 241,
        label: 'Allergists and Immunologists',
        parent: 8,
      },
      {
        value: 242,
        label: 'Anesthesiologists Assistants',
        parent: 8,
      },
      {
        value: 243,
        label: 'Anesthesiologists',
        parent: 8,
      },
      {
        value: 244,
        label: 'Athletic Trainers',
        parent: 8,
      },
      {
        value: 245,
        label: 'Biologists',
        parent: 8,
      },
      {
        value: 246,
        label: 'Biomedical Engineers',
        parent: 8,
      },
      {
        value: 247,
        label: 'Cardiovascular Technologists and Technicians',
        parent: 8,
      },
      {
        value: 248,
        label: 'Chiropractors',
        parent: 8,
      },
      {
        value: 249,
        label: 'Clinical Data Managers',
        parent: 8,
      },
      {
        value: 250,
        label: 'Clinicial Psychologists',
        parent: 8,
      },
      {
        value: 251,
        label: 'Clinical Research Coordinators',
        parent: 8,
      },
      {
        value: 252,
        label: 'Counseling Psychologists',
        parent: 8,
      },
      {
        value: 253,
        label: 'Dental Assistants',
        parent: 8,
      },
      {
        value: 254,
        label: 'Dental Hygienists',
        parent: 8,
      },
      {
        value: 255,
        label: 'Dental Laboratory Technicians',
        parent: 8,
      },
      {
        value: 256,
        label: 'Dentists',
        parent: 8,
      },
      {
        value: 257,
        label: 'Dermatologists',
        parent: 8,
      },
      {
        value: 258,
        label: 'Dietetic Technicians',
        parent: 8,
      },
      {
        value: 259,
        label: 'Dieticians and Nutrionists',
        parent: 8,
      },
      {
        value: 260,
        label: 'Epidemiologists',
        parent: 8,
      },
      {
        value: 261,
        label: 'Family and General Practitioners',
        parent: 8,
      },
      {
        value: 262,
        label: 'Health Educators',
        parent: 8,
      },
      {
        value: 263,
        label: 'Home Health Aides',
        parent: 8,
      },
      {
        value: 264,
        label: 'Hospitalists',
        parent: 8,
      },
      {
        value: 265,
        label: 'Internists',
        parent: 8,
      },
      {
        value: 266,
        label: 'Medical and Clinical Laboratory Technologists',
        parent: 8,
      },
      {
        value: 267,
        label: 'Medical Assistants',
        parent: 8,
      },
      {
        value: 268,
        label: 'Medical Equipment Prepares',
        parent: 8,
      },
      {
        value: 269,
        label: 'Mental Health and Substance Abuse Social Workers',
        parent: 8,
      },
      {
        value: 270,
        label: 'Neuroloists and Clinicial Neurologists',
        parent: 8,
      },
      {
        value: 271,
        label: 'Obstetricians and Gynecologists',
        parent: 8,
      },
      {
        value: 272,
        label: 'Occupational Therapists',
        parent: 8,
      },
      {
        value: 273,
        label: 'Occupational Therapist Aides',
        parent: 8,
      },
      {
        value: 274,
        label: 'Occupational Therapist Assistants',
        parent: 8,
      },
      {
        value: 275,
        label: 'Opthalmic Laboratory Technicians',
        parent: 8,
      },
      {
        value: 276,
        label: 'Opthalologists',
        parent: 8,
      },
      {
        value: 277,
        label: 'Opticians',
        parent: 8,
      },
      {
        value: 278,
        label: 'Optometrists',
        parent: 8,
      },
      {
        value: 279,
        label: 'Oral and Maxillofacial Surgeons',
        parent: 8,
      },
      {
        value: 280,
        label: 'Orthodontists',
        parent: 8,
      },
      {
        value: 281,
        label: 'Pathologists',
        parent: 8,
      },
      {
        value: 282,
        label: 'Patient Representatives',
        parent: 8,
      },
      {
        value: 283,
        label: 'Pediatricians',
        parent: 8,
      },
      {
        value: 284,
        label: 'Pharmacists',
        parent: 8,
      },
      {
        value: 285,
        label: 'Pharmacy Aides',
        parent: 8,
      },
      {
        value: 286,
        label: 'Pharmacy Technicians',
        parent: 8,
      },
      {
        value: 287,
        label: 'Phsycial Medicine and Rehabilitation Physicians ',
        parent: 8,
      },
      {
        value: 288,
        label: 'Physical Therapist Aides',
        parent: 8,
      },
      {
        value: 289,
        label: 'Phsyical Therapist Assistants',
        parent: 8,
      },
      {
        value: 290,
        label: 'Phsyical Therapists',
        parent: 8,
      },
      {
        value: 291,
        label: 'Podiatrists',
        parent: 8,
      },
      {
        value: 292,
        label: 'Preventive Medicine Physicians',
        parent: 8,
      },
      {
        value: 293,
        label: 'Psychiatric Tehnicians',
        parent: 8,
      },
      {
        value: 294,
        label: 'Pyschiatric Aides',
        parent: 8,
      },
      {
        value: 295,
        label: 'Pyschiatrists',
        parent: 8,
      },
      {
        value: 296,
        label: 'Radiation Therapists',
        parent: 8,
      },
      {
        value: 297,
        label: 'Radiologic Technologists',
        parent: 8,
      },
      {
        value: 298,
        label: 'Recreational Therapists',
        parent: 8,
      },
      {
        value: 299,
        label: 'Respiratory Therapists',
        parent: 8,
      },
      {
        value: 300,
        label: 'Respiratory Therapy Tehcnicians',
        parent: 8,
      },
      {
        value: 301,
        label: 'Sports Medicine Physicians',
        parent: 8,
      },
      {
        value: 302,
        label: 'Surgical Technologists',
        parent: 8,
      },
      {
        value: 303,
        label: 'Surgeons',
        parent: 8,
      },
      {
        value: 304,
        label: 'Urologists',
        parent: 8,
      },
      {
        value: 305,
        label: 'OTHER',
        parent: 8,
      },
    ],
  },
  {
    value: 9,
    label: 'Hospitality and Tourism',
    children: [
      {
        value: 306,
        label: 'Amusement and Recreation Attendants',
        parent: 9,
      },
      {
        value: 307,
        label: 'Animal Trainers',
        parent: 9,
      },
      {
        value: 308,
        label: 'Baggage Porters and Bellhops',
        parent: 9,
      },
      {
        value: 309,
        label: 'Bakers',
        parent: 9,
      },
      {
        value: 310,
        label: 'Baristas',
        parent: 9,
      },
      {
        value: 311,
        label: 'Chef and Head Cooks',
        parent: 9,
      },
      {
        value: 312,
        label: 'Concierges',
        parent: 9,
      },
      {
        value: 313,
        label: 'Cooks',
        parent: 9,
      },
      {
        value: 314,
        label: 'Dishwashers',
        parent: 9,
      },
      {
        value: 315,
        label: 'Food Preparation Workers',
        parent: 9,
      },
      {
        value: 316,
        label: 'Food Servers',
        parent: 9,
      },
      {
        value: 317,
        label: 'Food Service Managers',
        parent: 9,
      },
      {
        value: 318,
        label: 'Gaming and Sports Book Writers and Runners',
        parent: 9,
      },
      {
        value: 319,
        label: 'Gaming Cage Workers',
        parent: 9,
      },
      {
        value: 320,
        label: 'Gaming Change Persons and Booth Cashiers',
        parent: 9,
      },
      {
        value: 321,
        label: 'Gaming Dealers',
        parent: 9,
      },
      {
        value: 322,
        label: 'Gaming Managers',
        parent: 9,
      },
      {
        value: 323,
        label: 'Gaming Supervisors',
        parent: 9,
      },
      {
        value: 324,
        label: 'Hosts and Hostesses',
        parent: 9,
      },
      {
        value: 325,
        label: 'Hotel, Motel, and Resort Desk Clerks',
        parent: 9,
      },
      {
        value: 326,
        label: 'Interpreters and Translators',
        parent: 9,
      },
      {
        value: 327,
        label: 'Janitors and Cleaners',
        parent: 9,
      },
      {
        value: 328,
        label: 'Lodging Managers',
        parent: 9,
      },
      {
        value: 329,
        label: 'Meeting, Convention, and Event Planners',
        parent: 9,
      },
      {
        value: 330,
        label: 'Recreation Workers',
        parent: 9,
      },
      {
        value: 331,
        label: 'Set and Exhibit Designers',
        parent: 9,
      },
      {
        value: 332,
        label: 'Travel Agents',
        parent: 9,
      },
      {
        value: 333,
        label: 'Umpires, Referees, and other Sports Officials',
        parent: 9,
      },
      {
        value: 334,
        label: 'Ushers, Lobby Attendants, and Ticket Takers',
        parent: 9,
      },
      {
        value: 335,
        label: 'Waiters and Waitresses',
        parent: 9,
      },
      {
        value: 336,
        label: 'OTHER',
        parent: 9,
      },
    ],
  },
  {
    value: 10,
    label: 'Human Services',
    children: [
      {
        value: 337,
        label: 'Barbers',
        parent: 10,
      },
      {
        value: 338,
        label: 'Child, Family, and School Social Workers',
        parent: 10,
      },
      {
        value: 339,
        label: 'Childcare Workers',
        parent: 10,
      },
      {
        value: 340,
        label: 'Clergy',
        parent: 10,
      },
      {
        value: 341,
        label: 'Embalmers',
        parent: 10,
      },
      {
        value: 342,
        label: 'Fitness Trainers and Aerobics Instructors',
        parent: 10,
      },
      {
        value: 343,
        label: 'Funeral Attendants',
        parent: 10,
      },
      {
        value: 344,
        label: 'Hairdressers, Hairstylists, and Cosmetologists',
        parent: 10,
      },
      {
        value: 345,
        label: 'Healthcare Social Workers',
        parent: 10,
      },
      {
        value: 346,
        label: 'Laundry and Dry-Cleaning Workers',
        parent: 10,
      },
      {
        value: 347,
        label: 'Locker Room, Coatroom, and Dressing Room Attendants',
        parent: 10,
      },
      {
        value: 348,
        label: 'Manicurists and Pedicurists',
        parent: 10,
      },
      {
        value: 349,
        label: 'Marriage and Family Therapists',
        parent: 10,
      },
      {
        value: 350,
        label: 'Massage Therapists',
        parent: 10,
      },
      {
        value: 351,
        label: 'Mental Health Counselors',
        parent: 10,
      },
      {
        value: 352,
        label: 'Nannies',
        parent: 10,
      },
      {
        value: 353,
        label: 'Personal Care Aides',
        parent: 10,
      },
      {
        value: 354,
        label: 'Property, Real Estate, and Community Association Managers',
        parent: 10,
      },
      {
        value: 355,
        label: 'Rehabilitation Counselors',
        parent: 10,
      },
      {
        value: 356,
        label: 'Residential Advisors',
        parent: 10,
      },
      {
        value: 357,
        label: 'Skincare Specialists ',
        parent: 10,
      },
      {
        value: 358,
        label: 'Social and Community Service Managers',
        parent: 10,
      },
      {
        value: 359,
        label: 'Social and Human Service Assistants',
        parent: 10,
      },
      {
        value: 360,
        label: 'Sociologists',
        parent: 10,
      },
      {
        value: 361,
        label: 'Substance Abuse and Behavioral Disorder Counselors',
        parent: 10,
      },
      {
        value: 362,
        label: 'Tailors, Dressmakers, and Custom Sewers',
        parent: 10,
      },
      {
        value: 363,
        label: 'OTHER',
        parent: 10,
      },
    ],
  },
  {
    value: 11,
    label: 'Informational Technology',
    children: [
      {
        value: 364,
        label: 'Business Intelligence Analysts',
        parent: 11,
      },
      {
        value: 365,
        label: 'Computer and Information Research Scientists',
        parent: 11,
      },
      {
        value: 366,
        label: 'Computer Network Architects',
        parent: 11,
      },
      {
        value: 367,
        label: 'Computer Network Support Specialists',
        parent: 11,
      },
      {
        value: 368,
        label: 'Computer Programmers',
        parent: 11,
      },
      {
        value: 369,
        label: 'Computer Systems Analysts',
        parent: 11,
      },
      {
        value: 370,
        label: 'Computer Systems Engineers/Architects',
        parent: 11,
      },
      {
        value: 371,
        label: 'Computer User Support Specialists',
        parent: 11,
      },
      {
        value: 372,
        label: 'Data Warehousing Specialists',
        parent: 11,
      },
      {
        value: 373,
        label: 'Database Administrators',
        parent: 11,
      },
      {
        value: 374,
        label: 'Database Architects',
        parent: 11,
      },
      {
        value: 375,
        label: 'Document Management Specialists',
        parent: 11,
      },
      {
        value: 376,
        label: 'Geographic Information Systems Technicians',
        parent: 11,
      },
      {
        value: 377,
        label: 'Geospatial Information Scientists and Technologists',
        parent: 11,
      },
      {
        value: 378,
        label: 'Informatics Nurse Specialists',
        parent: 11,
      },
      {
        value: 379,
        label: 'Information Security Analysts',
        parent: 11,
      },
      {
        value: 380,
        label: 'Information Technology Project Managers',
        parent: 11,
      },
      {
        value: 381,
        label: 'Network and Computer System Administrators',
        parent: 11,
      },
      {
        value: 382,
        label: 'Search Marketing Strategists',
        parent: 11,
      },
      {
        value: 383,
        label: 'Software Developers, Applications',
        parent: 11,
      },
      {
        value: 384,
        label: 'Software Developers, System Software',
        parent: 11,
      },
      {
        value: 385,
        label: 'Software Quality Assurance Engineers and Testers',
        parent: 11,
      },
      {
        value: 386,
        label: 'Telecommunications Engineering Specialists',
        parent: 11,
      },
      {
        value: 387,
        label: 'Video Game Designers',
        parent: 11,
      },
      {
        value: 388,
        label: 'Web Administrators',
        parent: 11,
      },
      {
        value: 389,
        label: 'Web Developers',
        parent: 11,
      },
      {
        value: 390,
        label: 'OTHER',
        parent: 11,
      },
    ],
  },
  {
    value: 12,
    label: 'Legal',
    children: [
      {
        value: 391,
        label: 'Arson Investigator',
        parent: 12,
      },
      {
        value: 392,
        label: 'Bankrupcty Attorney',
        parent: 12,
      },
      {
        value: 393,
        label: 'Business Attorney',
        parent: 12,
      },
      {
        value: 394,
        label: 'Casino Security Officer',
        parent: 12,
      },
      {
        value: 395,
        label: 'Commercial Real Estate Attorney',
        parent: 12,
      },
      {
        value: 396,
        label: 'Constitutional Attorney',
        parent: 12,
      },
      {
        value: 397,
        label: 'Constitutional Law Professors',
        parent: 12,
      },
      {
        value: 398,
        label: 'Copyright Attorney',
        parent: 12,
      },
      {
        value: 399,
        label: 'Coroner',
        parent: 12,
      },
      {
        value: 400,
        label: 'Corporate Attorney',
        parent: 12,
      },
      {
        value: 401,
        label: 'Corporate Security',
        parent: 12,
      },
      {
        value: 402,
        label: 'Court Adminstration',
        parent: 12,
      },
      {
        value: 403,
        label: 'Court Bailiff',
        parent: 12,
      },
      {
        value: 404,
        label: 'Court Clerk',
        parent: 12,
      },
      {
        value: 405,
        label: 'Court Interpreter',
        parent: 12,
      },
      {
        value: 406,
        label: 'Court Recorder',
        parent: 12,
      },
      {
        value: 407,
        label: 'Court Stenographer',
        parent: 12,
      },
      {
        value: 408,
        label: 'Court Transcriptionist',
        parent: 12,
      },
      {
        value: 409,
        label: 'Crime Lab Technician',
        parent: 12,
      },
      {
        value: 410,
        label: 'Crime Scene Investigation',
        parent: 12,
      },
      {
        value: 411,
        label: 'Criminal Attorney',
        parent: 12,
      },
      {
        value: 412,
        label: 'Discrict Court Judge',
        parent: 12,
      },
      {
        value: 413,
        label: 'Divorce Attorney',
        parent: 12,
      },
      {
        value: 414,
        label: 'Environmental Attorney',
        parent: 12,
      },
      {
        value: 415,
        label: 'Evidence Technician',
        parent: 12,
      },
      {
        value: 416,
        label: 'Family Attorney',
        parent: 12,
      },
      {
        value: 417,
        label: 'Family Law Specialist',
        parent: 12,
      },
      {
        value: 418,
        label: 'Firefighter',
        parent: 12,
      },
      {
        value: 419,
        label: 'Foreclosure Attorney',
        parent: 12,
      },
      {
        value: 420,
        label: 'Forensic Engineer',
        parent: 12,
      },
      {
        value: 421,
        label: 'Forensic Laboratory Technician',
        parent: 12,
      },
      {
        value: 422,
        label: 'Forensic Science Technicia',
        parent: 12,
      },
      {
        value: 423,
        label: 'Health Care Attorney',
        parent: 12,
      },
      {
        value: 424,
        label: 'Human Rights Attorney',
        parent: 12,
      },
      {
        value: 425,
        label: 'Immigration Attorney',
        parent: 12,
      },
      {
        value: 426,
        label: 'International Law Attorney',
        parent: 12,
      },
      {
        value: 427,
        label: 'Juvenile Parole Officer',
        parent: 12,
      },
      {
        value: 428,
        label: 'Law Enforcement Management',
        parent: 12,
      },
      {
        value: 429,
        label: 'Law Enforcement Officer',
        parent: 12,
      },
      {
        value: 430,
        label: 'Legal Administrative Professional',
        parent: 12,
      },
      {
        value: 431,
        label: 'Legal Aid',
        parent: 12,
      },
      {
        value: 432,
        label: 'Legal Executive Assistant',
        parent: 12,
      },
      {
        value: 433,
        label: 'Legal File Clerk',
        parent: 12,
      },
      {
        value: 434,
        label: 'Legal Nurse Consultant',
        parent: 12,
      },
      {
        value: 435,
        label: 'Legal Research Assistant',
        parent: 12,
      },
      {
        value: 436,
        label: 'Legal Secretaries',
        parent: 12,
      },
      {
        value: 437,
        label: 'Litigation Attorney',
        parent: 12,
      },
      {
        value: 438,
        label: 'Loss Prevention Surveillence Officer',
        parent: 12,
      },
      {
        value: 439,
        label: 'Magistrate',
        parent: 12,
      },
      {
        value: 440,
        label: 'Mediator',
        parent: 12,
      },
      {
        value: 441,
        label: 'Medical Law Attorney',
        parent: 12,
      },
      {
        value: 442,
        label: 'Paralegal',
        parent: 12,
      },
      {
        value: 443,
        label: 'Police Detective',
        parent: 12,
      },
      {
        value: 444,
        label: 'Police Dispatcher',
        parent: 12,
      },
      {
        value: 445,
        label: 'Police State Trooper',
        parent: 12,
      },
      {
        value: 446,
        label: 'Public Security Administrator',
        parent: 12,
      },
      {
        value: 447,
        label: 'Publishing Law Attorney',
        parent: 12,
      },
      {
        value: 448,
        label: 'Scopist',
        parent: 12,
      },
      {
        value: 449,
        label: 'State Corrections Office',
        parent: 12,
      },
      {
        value: 450,
        label: 'Transit Police Officer',
        parent: 12,
      },
      {
        value: 451,
        label: 'U.S. Drug Enforcement Administrator',
        parent: 12,
      },
      {
        value: 452,
        label: 'Water Rights Attorney',
        parent: 12,
      },
      {
        value: 453,
        label: 'Youth Correctional Officer',
        parent: 12,
      },
      {
        value: 454,
        label: 'Youth Corrections Administrator',
        parent: 12,
      },
      {
        value: 455,
        label: 'OTHER',
        parent: 12,
      },
    ],
  },
  {
    value: 13,
    label: 'Manufacturing',
    children: [
      {
        value: 456,
        label: 'Aircraft Structure, Surfaces, Rigging, and Systems Assemblers',
        parent: 13,
      },
      {
        value: 457,
        label: 'Biofuels Processing Technicians',
        parent: 13,
      },
      {
        value: 458,
        label: 'Biomass Plant Technicians',
        parent: 13,
      },
      {
        value: 459,
        label: 'Boilermakers',
        parent: 13,
      },
      {
        value: 460,
        label: 'Cabinetmakers and Bench Carpenters',
        parent: 13,
      },
      {
        value: 461,
        label: 'Chemical Equipment Operators and Tenders',
        parent: 13,
      },
      {
        value: 462,
        label: 'Chemical Plant and System Operators',
        parent: 13,
      },
      {
        value: 463,
        label: 'Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders ',
        parent: 13,
      },
      {
        value: 464,
        label: 'Coating, Painting, and Spray Machine Setters, Operators, and Tenders',
        parent: 13,
      },
      {
        value: 465,
        label: 'Coil Winders, Tapers, and Finishers',
        parent: 13,
      },
      {
        value: 466,
        label: 'Coin, Vending, and Amusement Machine Servicers and Repairers',
        parent: 13,
      },
      {
        value: 467,
        label: 'Computer Numerically Controlled Machine Tool Programmers, Metal and Plastic',
        parent: 13,
      },
      {
        value: 468,
        label: 'Computer, Automated Teller, and Office Machine Repairers',
        parent: 13,
      },
      {
        value: 469,
        label: 'Cooling and Freezing Equipment Operators',
        parent: 13,
      },
      {
        value: 470,
        label: 'Cutters and Trimmers, Hand',
        parent: 13,
      },
      {
        value: 471,
        label: 'Cutting and Slicing Machine Setters, Operators, and Tenders',
        parent: 13,
      },
      {
        value: 472,
        label: 'Cutting and Slicing Machine Setters, Operators, and Tenders, Metal and Plastic',
        parent: 13,
      },
      {
        value: 473,
        label: 'Drilling and Boring Machine Tool Setters, Operators, and Tenders, Metal and Plastic',
        parent: 13,
      },
      {
        value: 474,
        label: 'Electric Motor, Power Tool, and Related Repairers',
        parent: 13,
      },
      {
        value: 475,
        label: 'Electrical and Electronic Equipment Assemblers',
        parent: 13,
      },
      {
        value: 476,
        label: 'Electrical and Electronics Repairers, Commericial and Industrial Equipment',
        parent: 13,
      },
      {
        value: 477,
        label: 'Electromechanical Equipment Assemblers',
        parent: 13,
      },
      {
        value: 478,
        label: 'Electronic Home Entertainment Equipment Installers and Repairers',
        parent: 13,
      },
      {
        value: 479,
        label: 'Engine and Other Machine Assemblers',
        parent: 13,
      },
      {
        value: 480,
        label: 'Etchers and Engravers',
        parent: 13,
      },
      {
        value: 481,
        label: 'Fabric and Apparel Patternmakers',
        parent: 13,
      },
      {
        value: 482,
        label: 'Fabric Menders',
        parent: 13,
      },
      {
        value: 483,
        label: 'Fiberglass Laminators and Fabricators',
        parent: 13,
      },
      {
        value: 484,
        label: 'Furniture Finishers',
        parent: 13,
      },
      {
        value: 485,
        label: 'Gem and Diamond Workers',
        parent: 13,
      },
      {
        value: 486,
        label: 'Glass Blowers, Molders, Benders, and Finishers',
        parent: 13,
      },
      {
        value: 487,
        label: 'Grinding and Polishing Workers',
        parent: 13,
      },
      {
        value: 488,
        label: 'Hydroelectric Plant Technicians',
        parent: 13,
      },
      {
        value: 489,
        label: 'Industrial Engineering Technicians',
        parent: 13,
      },
      {
        value: 490,
        label: 'Industrial Machinery Mechanics',
        parent: 13,
      },
      {
        value: 491,
        label: 'Industrial Production Managers',
        parent: 13,
      },
      {
        value: 492,
        label: 'Inspectors, Testers, Sorters, Samplers, and Weighers',
        parent: 13,
      },
      {
        value: 493,
        label: 'Jewelers',
        parent: 13,
      },
      {
        value: 494,
        label: 'Locksmiths and Safe Repairers',
        parent: 13,
      },
      {
        value: 495,
        label: 'Machinists',
        parent: 13,
      },
      {
        value: 496,
        label: 'Maintenance Workers',
        parent: 13,
      },
      {
        value: 497,
        label: 'Manufacturing Production Technicians',
        parent: 13,
      },
      {
        value: 498,
        label: 'Mechanical Engineering Technicians',
        parent: 13,
      },
      {
        value: 499,
        label: 'Medical Appliance Technicians',
        parent: 13,
      },
      {
        value: 500,
        label: 'Medical Equipment Repairers',
        parent: 13,
      },
      {
        value: 501,
        label: 'Molding and Casting Workers',
        parent: 13,
      },
      {
        value: 502,
        label: 'Nuclear Power Reactor Operators',
        parent: 13,
      },
      {
        value: 503,
        label: 'Outdoor Power Equipment and Other Small Engine Mechanics',
        parent: 13,
      },
      {
        value: 504,
        label: 'Packaging and Filling Machine Operators and Tenders',
        parent: 13,
      },
      {
        value: 505,
        label: 'Painters',
        parent: 13,
      },
      {
        value: 506,
        label: 'Power Plant Operators',
        parent: 13,
      },
      {
        value: 507,
        label: 'Precious Metal Workers',
        parent: 13,
      },
      {
        value: 508,
        label: 'Radio, Cellular, and Tower Equipment Installers and Repairers',
        parent: 13,
      },
      {
        value: 509,
        label: 'Robotics Technicians',
        parent: 13,
      },
      {
        value: 510,
        label: 'Security and Fire Alarm System Installers',
        parent: 13,
      },
      {
        value: 511,
        label: 'Semiconductor Processors',
        parent: 13,
      },
      {
        value: 512,
        label: 'Stone Cutters and Carvers',
        parent: 13,
      },
      {
        value: 513,
        label: 'Team Assemblers',
        parent: 13,
      },
      {
        value: 514,
        label: 'Upholsterers',
        parent: 13,
      },
      {
        value: 515,
        label: 'Watch Repairers',
        parent: 13,
      },
      {
        value: 516,
        label: 'Welders, Cutters, Solderers, and Brazers',
        parent: 13,
      },
      {
        value: 517,
        label: 'Woodworking Machine Setters, Operators, and Tenders',
        parent: 13,
      },
      {
        value: 518,
        label: 'OTHER',
        parent: 13,
      },
    ],
  },
  {
    value: 14,
    label: 'Marketing, Sales and Service',
    children: [
      {
        value: 519,
        label: 'Appraisers, Real Estate',
        parent: 14,
      },
      {
        value: 520,
        label: 'Automotive and Watercraft Service Attendants',
        parent: 14,
      },
      {
        value: 521,
        label: 'Cashiers',
        parent: 14,
      },
      {
        value: 522,
        label: 'Counter and Rental Clerks',
        parent: 14,
      },
      {
        value: 523,
        label: 'Customer Service Representatives',
        parent: 14,
      },
      {
        value: 524,
        label: 'Demonstrators and Product Promoters',
        parent: 14,
      },
      {
        value: 525,
        label: 'Door-to-Door Sales Workers, Street Vendors, and Related Workers',
        parent: 14,
      },
      {
        value: 526,
        label: 'Fashion Designers',
        parent: 14,
      },
      {
        value: 527,
        label: 'Floral Designers',
        parent: 14,
      },
      {
        value: 528,
        label: 'Green Marketers',
        parent: 14,
      },
      {
        value: 529,
        label: 'Marking Clerks',
        parent: 14,
      },
      {
        value: 530,
        label: 'Models',
        parent: 14,
      },
      {
        value: 531,
        label: 'Office Clerks, General',
        parent: 14,
      },
      {
        value: 532,
        label: 'Order Clerks',
        parent: 14,
      },
      {
        value: 533,
        label: 'Parking Lot Attendants',
        parent: 14,
      },
      {
        value: 534,
        label: 'Procurement Clerks',
        parent: 14,
      },
      {
        value: 535,
        label: 'Real Estate Brokers',
        parent: 14,
      },
      {
        value: 536,
        label: 'Real Estate Sales Agents',
        parent: 14,
      },
      {
        value: 537,
        label: 'Retail Salespersons',
        parent: 14,
      },
      {
        value: 538,
        label: 'Sales Engineers',
        parent: 14,
      },
      {
        value: 539,
        label: 'Sales Representatives',
        parent: 14,
      },
      {
        value: 540,
        label: 'Solar Sales Representative and Assessors',
        parent: 14,
      },
      {
        value: 541,
        label: 'Stock Clerks, Sales Floor',
        parent: 14,
      },
      {
        value: 542,
        label: 'Telemarketers',
        parent: 14,
      },
      {
        value: 543,
        label: 'Wholesale and Retail Buyers',
        parent: 14,
      },
      {
        value: 544,
        label: 'OTHER',
        parent: 14,
      },
    ],
  },
  {
    value: 15,
    label: 'Science, Technology, Engineering and Mathematics',
    children: [
      {
        value: 545,
        label: 'Aerospace Engineers',
        parent: 15,
      },
      {
        value: 546,
        label: 'Agricultural and Food Science Technicians',
        parent: 15,
      },
      {
        value: 547,
        label: 'Agricultural Engineers',
        parent: 15,
      },
      {
        value: 548,
        label: 'Agricultural Technicians',
        parent: 15,
      },
      {
        value: 549,
        label: 'Anthropologists',
        parent: 15,
      },
      {
        value: 550,
        label: 'Anthropologists and Archeologists',
        parent: 15,
      },
      {
        value: 551,
        label: 'Archeologists',
        parent: 15,
      },
      {
        value: 552,
        label: 'Architectural and Civil Drafters',
        parent: 15,
      },
      {
        value: 553,
        label: 'Architectural and Engineering Managers',
        parent: 15,
      },
      {
        value: 554,
        label: 'Architectural Drafters',
        parent: 15,
      },
      {
        value: 555,
        label: 'Astronomers',
        parent: 15,
      },
      {
        value: 556,
        label: 'Atmospheric and Space Scientists',
        parent: 15,
      },
      {
        value: 557,
        label: 'Automotive Engineering Technicians',
        parent: 15,
      },
      {
        value: 558,
        label: 'Automotive Engineers',
        parent: 15,
      },
      {
        value: 559,
        label: 'Biochemical Engineers',
        parent: 15,
      },
      {
        value: 560,
        label: 'Biochemists and Biophysicists',
        parent: 15,
      },
      {
        value: 561,
        label: 'Biofuels/Biodiesel Technology and Product Development Managers',
        parent: 15,
      },
      {
        value: 562,
        label: 'Bioinformatics Scientists',
        parent: 15,
      },
      {
        value: 563,
        label: 'Bioinformatic Technicians',
        parent: 15,
      },
      {
        value: 564,
        label: 'Biological Technicians',
        parent: 15,
      },
      {
        value: 565,
        label: 'Biostatisticians',
        parent: 15,
      },
      {
        value: 566,
        label: 'Chemical Engineers',
        parent: 15,
      },
      {
        value: 567,
        label: 'Chemical Technicians',
        parent: 15,
      },
      {
        value: 568,
        label: 'Chemists',
        parent: 15,
      },
      {
        value: 569,
        label: 'Civil Drafters',
        parent: 15,
      },
      {
        value: 570,
        label: 'Computer Hardware Engineers',
        parent: 15,
      },
      {
        value: 571,
        label: 'Conservation Scientists',
        parent: 15,
      },
      {
        value: 572,
        label: 'Electrical and Electronic Engineering Technicians',
        parent: 15,
      },
      {
        value: 573,
        label: 'Electrical and Electronic Drafters',
        parent: 15,
      },
      {
        value: 574,
        label: 'Electronic Drafters',
        parent: 15,
      },
      {
        value: 575,
        label: 'Electrical Engineering Technicians',
        parent: 15,
      },
      {
        value: 576,
        label: 'Electrical Engineering Technologists',
        parent: 15,
      },
      {
        value: 577,
        label: 'Electrical Engineers',
        parent: 15,
      },
      {
        value: 578,
        label: 'Electro-Mechanical Technicians',
        parent: 15,
      },
      {
        value: 579,
        label: 'Electromechanical Engineering Technologists',
        parent: 15,
      },
      {
        value: 580,
        label: 'Electronics Engineering Technicians',
        parent: 15,
      },
      {
        value: 581,
        label: 'Electronics Engineering Technologists',
        parent: 15,
      },
      {
        value: 582,
        label: 'Electronic Engineers',
        parent: 15,
      },
      {
        value: 583,
        label: 'Energy Engineers',
        parent: 15,
      },
      {
        value: 584,
        label: 'Environmental Economists',
        parent: 15,
      },
      {
        value: 585,
        label: 'Environmental Restoration Planners',
        parent: 15,
      },
      {
        value: 586,
        label: 'Environmental Scientists and Specialists',
        parent: 15,
      },
      {
        value: 587,
        label: 'Food Science Technicians',
        parent: 15,
      },
      {
        value: 588,
        label: 'Fuel Cell Engineers',
        parent: 15,
      },
      {
        value: 589,
        label: 'Fuel Cell Technicians',
        parent: 15,
      },
      {
        value: 590,
        label: 'Geodetic Surveyors',
        parent: 15,
      },
      {
        value: 591,
        label: 'Geographers',
        parent: 15,
      },
      {
        value: 592,
        label: 'Geoscientists',
        parent: 15,
      },
      {
        value: 593,
        label: 'Health and Safety Engineers',
        parent: 15,
      },
      {
        value: 594,
        label: 'Historians',
        parent: 15,
      },
      {
        value: 595,
        label: 'Hydrologists',
        parent: 15,
      },
      {
        value: 596,
        label: 'Industrial Ecologists',
        parent: 15,
      },
      {
        value: 597,
        label: 'Industrial Engineering Technologists',
        parent: 15,
      },
      {
        value: 598,
        label: 'Industrial Engineers',
        parent: 15,
      },
      {
        value: 599,
        label: 'Manufacturing Engineering Technologists',
        parent: 15,
      },
      {
        value: 600,
        label: 'Manufacturing Engineers',
        parent: 15,
      },
      {
        value: 601,
        label: 'Mapping Technicians',
        parent: 15,
      },
      {
        value: 602,
        label: 'Marine Engineers',
        parent: 15,
      },
      {
        value: 603,
        label: 'Marine Engineers and Naval Architects',
        parent: 15,
      },
      {
        value: 604,
        label: 'Materials Engineers',
        parent: 15,
      },
      {
        value: 605,
        label: 'Materials Scientials',
        parent: 15,
      },
      {
        value: 606,
        label: 'Mathmeticians',
        parent: 15,
      },
      {
        value: 607,
        label: 'Mechanical Engineering Technologists',
        parent: 15,
      },
      {
        value: 608,
        label: 'Mechanic Engineers',
        parent: 15,
      },
      {
        value: 609,
        label: 'Mechatronics Engineers',
        parent: 15,
      },
      {
        value: 610,
        label: 'Microbiologists',
        parent: 15,
      },
      {
        value: 611,
        label: 'Mining and Geological Engineers',
        parent: 15,
      },
      {
        value: 612,
        label: 'Molecular and Cellular Biologists',
        parent: 15,
      },
      {
        value: 613,
        label: 'Nanosystems Engineers',
        parent: 15,
      },
      {
        value: 614,
        label: 'Nanotechnology Engineering Technicians',
        parent: 15,
      },
      {
        value: 615,
        label: 'Nanotechnology Engineering Specialists',
        parent: 15,
      },
      {
        value: 616,
        label: 'Nuclear Engineers',
        parent: 15,
      },
      {
        value: 617,
        label: 'Nuclear Equipment Operation Technicians',
        parent: 15,
      },
      {
        value: 618,
        label: 'Nuclear Monitoring Technicians',
        parent: 15,
      },
      {
        value: 619,
        label: 'Nuclear Technicians',
        parent: 15,
      },
      {
        value: 620,
        label: 'Park Naturalists',
        parent: 15,
      },
      {
        value: 621,
        label: 'Petroleum Engineers',
        parent: 15,
      },
      {
        value: 622,
        label: 'Physicists',
        parent: 15,
      },
      {
        value: 623,
        label: 'Political Scientists',
        parent: 15,
      },
      {
        value: 624,
        label: 'Product Safety Engineers',
        parent: 15,
      },
      {
        value: 625,
        label: 'Quality Control Analysts',
        parent: 15,
      },
      {
        value: 626,
        label: 'Quality Control Systems Manager',
        parent: 15,
      },
      {
        value: 627,
        label: 'Remote Sensing Technicians',
        parent: 15,
      },
      {
        value: 628,
        label: 'Robotics Engineers',
        parent: 15,
      },
      {
        value: 629,
        label: 'Social Science Research Assistants',
        parent: 15,
      },
      {
        value: 630,
        label: 'Solar Energy Systems Engineers',
        parent: 15,
      },
      {
        value: 631,
        label: 'Statisticians',
        parent: 15,
      },
      {
        value: 632,
        label: 'Survey Researchers',
        parent: 15,
      },
      {
        value: 633,
        label: 'Surveying and Mapping Technicians',
        parent: 15,
      },
      {
        value: 634,
        label: 'Surveying Technicians',
        parent: 15,
      },
      {
        value: 635,
        label: 'Technical Writers',
        parent: 15,
      },
      {
        value: 636,
        label: 'Transpotation Engineers',
        parent: 15,
      },
      {
        value: 637,
        label: 'Validation Engineers',
        parent: 15,
      },
      {
        value: 638,
        label: 'Water Resource Specialists',
        parent: 15,
      },
      {
        value: 639,
        label: 'Wind Energy Engineers',
        parent: 15,
      },
      {
        value: 640,
        label: 'OTHER',
        parent: 15,
      },
    ],
  },
  {
    value: 16,
    label: 'Transportation, Distribution, and Logistics',
    children: [
      {
        value: 641,
        label: ' Aerospace Engineering and Operations Technicians',
        parent: 16,
      },
      {
        value: 642,
        label: 'Air Traffic Controllers',
        parent: 16,
      },
      {
        value: 643,
        label: 'Aircraft Cargo Handling Supervisors',
        parent: 16,
      },
      {
        value: 644,
        label: 'Aircraft Mechanics and Service Technicians',
        parent: 16,
      },
      {
        value: 645,
        label: 'Airflied Operations Specialists',
        parent: 16,
      },
      {
        value: 646,
        label: 'Airline Pilots, Copilots, and Flight Engineers',
        parent: 16,
      },
      {
        value: 647,
        label: 'Automotive Body and Related  Repairers',
        parent: 16,
      },
      {
        value: 648,
        label: 'Automotive Master Mechanics',
        parent: 16,
      },
      {
        value: 649,
        label: 'Automotive Service Technicians and Mechanics',
        parent: 16,
      },
      {
        value: 650,
        label: 'Automotive Speciality Technicians',
        parent: 16,
      },
      {
        value: 651,
        label: 'Aviation Inspectors',
        parent: 16,
      },
      {
        value: 652,
        label: 'Avionics Technicians',
        parent: 16,
      },
      {
        value: 653,
        label: 'Billing and Posting Clerks',
        parent: 16,
      },
      {
        value: 654,
        label: 'Bridge and Lock Tenders',
        parent: 16,
      },
      {
        value: 655,
        label: 'Bus and Truck Mechanics and Diesel Engine Specialists',
        parent: 16,
      },
      {
        value: 656,
        label: 'Bus Drivers, School or Special Client ',
        parent: 16,
      },
      {
        value: 657,
        label: 'Bus Drivers, Transit and Intercity',
        parent: 16,
      },
      {
        value: 658,
        label: 'Captains, Mates, and Pilots of Water Vessels',
        parent: 16,
      },
      {
        value: 659,
        label: 'Cargo and Freight Agents',
        parent: 16,
      },
      {
        value: 660,
        label: 'Commercial Pilots',
        parent: 16,
      },
      {
        value: 661,
        label: 'Customs Brokers',
        parent: 16,
      },
      {
        value: 662,
        label: 'Dispatchers',
        parent: 16,
      },
      {
        value: 663,
        label: 'Freight and Cargo Inspectors',
        parent: 16,
      },
      {
        value: 664,
        label: 'Heavy and Tractor-Trailer Truck Drivers',
        parent: 16,
      },
      {
        value: 665,
        label: 'Industrial Safety and Health Engineers',
        parent: 16,
      },
      {
        value: 666,
        label: 'Industrial Truck and Tractor Operators',
        parent: 16,
      },
      {
        value: 667,
        label: 'Laborers and Freight, Stock and Marterial Movers',
        parent: 16,
      },
      {
        value: 668,
        label: 'Light Truck or Delivery Service Drivers',
        parent: 16,
      },
      {
        value: 669,
        label: 'Locomotive Engineers',
        parent: 16,
      },
      {
        value: 670,
        label: 'Locomotive Firers',
        parent: 16,
      },
      {
        value: 671,
        label: 'Logisticians ',
        parent: 16,
      },
      {
        value: 672,
        label: 'Logistics Analysts',
        parent: 16,
      },
      {
        value: 673,
        label: 'Mates- Ship, Boat, and Barge',
        parent: 16,
      },
      {
        value: 674,
        label: 'Motorboat Mechanics and Service Technicians',
        parent: 16,
      },
      {
        value: 675,
        label: 'Motorboat Operators',
        parent: 16,
      },
      {
        value: 676,
        label: 'Motorcycle Mechanics',
        parent: 16,
      },
      {
        value: 677,
        label: 'Packers and Packagers',
        parent: 16,
      },
      {
        value: 678,
        label: 'Rail Car Repairers',
        parent: 16,
      },
      {
        value: 679,
        label: 'Rail Yard Engineers, Dinkey Operators, Hostlers',
        parent: 16,
      },
      {
        value: 680,
        label: 'Rail-Track Laying and Maintenance Equipment Operators',
        parent: 16,
      },
      {
        value: 681,
        label: 'Railforad Brake, Signal, and Switch Operators',
        parent: 16,
      },
      {
        value: 682,
        label: 'Railroad Conductors and Yardmasters',
        parent: 16,
      },
      {
        value: 683,
        label: 'Recreational Vehicle Service Technicians',
        parent: 16,
      },
      {
        value: 684,
        label: 'Sailors and Marine Oilers',
        parent: 16,
      },
      {
        value: 685,
        label: 'Ship and Boat Captains',
        parent: 16,
      },
      {
        value: 686,
        label: 'Ship Engineers',
        parent: 16,
      },
      {
        value: 687,
        label: 'Shipping, Receiving, and Traffic Clerks',
        parent: 16,
      },
      {
        value: 688,
        label: 'Signal and Track Switch Repairers',
        parent: 16,
      },
      {
        value: 689,
        label: 'Storage and Distribution Managers',
        parent: 16,
      },
      {
        value: 690,
        label: 'Subway and Streetcar Operators',
        parent: 16,
      },
      {
        value: 691,
        label: 'Supply Chain Managers',
        parent: 16,
      },
      {
        value: 692,
        label: 'Tank Car, Truck, and Ship Loaders',
        parent: 16,
      },
      {
        value: 693,
        label: 'Taxi Drivers and Chauffeurs',
        parent: 16,
      },
      {
        value: 694,
        label: 'Tire Repairers and Changers',
        parent: 16,
      },
      {
        value: 695,
        label: 'Traffic Technicians',
        parent: 16,
      },
      {
        value: 696,
        label: 'Transportation Managers',
        parent: 16,
      },
      {
        value: 697,
        label: 'Transportation Planners',
        parent: 16,
      },
      {
        value: 698,
        label: 'Transportation Vehicle, Equipment and System Inspectors',
        parent: 16,
      },
    ],
  },
];

import React, { FC, useEffect } from 'react';
import { Input } from 'antd';
import './InputPromo.less';
import { useQueryParams } from '../../../hooks';

/**
 * @value coming from parent component
 */
const InputPromo: FC<any> = ({ onChange = (data: any) => null, value = '' }: any) => {
  const { code, promoCode } = useQueryParams();

  useEffect(() => {
    onChange({ target: { value: code || promoCode } });
  }, []);

  return (
    <div className="input-promo-root">
      <div className="input-promo">
        <div className="label-container">Promo Code</div>

        <div className="input-container">
          <Input type="text" size="small" placeholder="XYZ" onChange={onChange} value={value} />
        </div>
      </div>
    </div>
  );
};

export { InputPromo };

export const masters = [
  {
    value: 1,
    label: 'Accountancy',
  },
  {
    value: 2,
    label: 'Advanced Study',
  },
  {
    value: 3,
    label: 'Applied Finance',
  },
  {
    value: 4,
    label: 'Applied Science',
  },
  {
    value: 5,
    label: 'Architecture',
  },
  {
    value: 6,
    label: 'Arts',
  },
  {
    value: 7,
    label: 'Arts in Liberal Studies',
  },
  {
    value: 8,
    label: 'Arts in Special Education',
  },
  {
    value: 9,
    label: 'Arts in Teaching',
  },
  {
    value: 10,
    label: 'Bioethics',
  },
  {
    value: 11,
    label: 'Business',
  },
  {
    value: 12,
    label: 'Business Administration',
  },
  {
    value: 13,
    label: 'Business Engineering',
  },
  {
    value: 14,
    label: 'Business Informatics',
  },
  {
    value: 15,
    label: 'Business, Entrepreneurship and Technology',
  },
  {
    value: 16,
    label: 'Chemistry',
  },
  {
    value: 17,
    label: 'Christian Education',
  },
  {
    value: 18,
    label: 'City Planning',
  },
  {
    value: 19,
    label: 'Commerce',
  },
  {
    value: 20,
    label: 'Computational Finance',
  },
  {
    value: 21,
    label: 'Computer Applications',
  },
  {
    value: 22,
    label: 'Counselling',
  },
  {
    value: 23,
    label: 'Creative Technologies',
  },
  {
    value: 24,
    label: 'Criminal Justice',
  },
  {
    value: 25,
    label: 'Data Science',
  },
  {
    value: 26,
    label: 'Defence Studies',
  },
  {
    value: 27,
    label: 'Design',
  },
  {
    value: 28,
    label: 'Divinity',
  },
  {
    value: 29,
    label: 'Economics',
  },
  {
    value: 30,
    label: 'Education',
  },
  {
    value: 31,
    label: 'Engineering',
  },
  {
    value: 32,
    label: 'Engineering Management',
  },
  {
    value: 33,
    label: 'Enterprise',
  },
  {
    value: 34,
    label: 'European Law',
  },
  {
    value: 35,
    label: 'Finance',
  },
  {
    value: 36,
    label: 'Financial Economics',
  },
  {
    value: 37,
    label: 'Financial Engineering',
  },
  {
    value: 38,
    label: 'Financial Mathematics',
  },
  {
    value: 39,
    label: 'Fine Arts',
  },
  {
    value: 40,
    label: 'Health Administration',
  },
  {
    value: 41,
    label: 'Health Economics',
  },
  {
    value: 42,
    label: 'Health Science',
  },
  {
    value: 43,
    label: 'Humanities',
  },
  {
    value: 44,
    label: 'Industrial and Labor Relations',
  },
  {
    value: 45,
    label: 'Information and Cybersecurity',
  },
  {
    value: 46,
    label: 'Information and Data Science',
  },
  {
    value: 47,
    label: 'Information Management',
  },
  {
    value: 48,
    label: 'Information System Management',
  },
  {
    value: 49,
    label: 'International Affairs',
  },
  {
    value: 50,
    label: 'International Business',
  },
  {
    value: 51,
    label: 'International Studies',
  },
  {
    value: 52,
    label: 'Journalism',
  },
  {
    value: 53,
    label: 'Jurisprudence',
  },
  {
    value: 54,
    label: 'Landscape Architecture',
  },
  {
    value: 55,
    label: 'Laws',
  },
  {
    value: 56,
    label: 'Letters',
  },
  {
    value: 57,
    label: 'Liberal Arts',
  },
  {
    value: 58,
    label: 'Library and Information Science[1]',
  },
  {
    value: 59,
    label: 'Management',
  },
  {
    value: 60,
    label: 'Management of Innovation',
  },
  {
    value: 61,
    label: 'Mass Communication',
  },
  {
    value: 62,
    label: 'Masters of International Economics',
  },
  {
    value: 63,
    label: 'Masters of Military Art and Science',
  },
  {
    value: 64,
    label: 'Mathematical Finance',
  },
  {
    value: 65,
    label: 'Mathematics',
  },
  {
    value: 66,
    label: 'Medical Science',
  },
  {
    value: 67,
    label: 'Medicine',
  },
  {
    value: 68,
    label: 'Military Operational Art and Science',
  },
  {
    value: 69,
    label: 'Ministry',
  },
  {
    value: 70,
    label: 'Music',
  },
  {
    value: 71,
    label: 'Occupational Behaviour and Development',
  },
  {
    value: 72,
    label: 'Occupational Therapy',
  },
  {
    value: 73,
    label: 'Pharmacy',
  },
  {
    value: 74,
    label: 'Philosophy',
  },
  {
    value: 75,
    label: 'Physician Assistant Studies',
  },
  {
    value: 76,
    label: 'Physics',
  },
  {
    value: 77,
    label: 'Political Science',
  },
  {
    value: 78,
    label: 'Professional Studies',
  },
  {
    value: 79,
    label: 'Psychology',
  },
  {
    value: 80,
    label: 'Public Administration',
  },
  {
    value: 81,
    label: 'Public Affairs',
  },
  {
    value: 82,
    label: 'Public Health',
  },
  {
    value: 83,
    label: 'Public Management',
  },
  {
    value: 84,
    label: 'Public Policy',
  },
  {
    value: 85,
    label: 'Public Relations',
  },
  {
    value: 86,
    label: 'Public Service',
  },
  {
    value: 87,
    label: 'Quantitative Finance',
  },
  {
    value: 88,
    label: 'Rabbinic Studies',
  },
  {
    value: 89,
    label: 'Real Estate Development',
  },
  {
    value: 90,
    label: 'Religious Education',
  },
  {
    value: 91,
    label: 'Research',
  },
  {
    value: 92,
    label: 'Sacred Music',
  },
  {
    value: 93,
    label: 'Sacred Theology',
  },
  {
    value: 94,
    label: 'Science',
  },
  {
    value: 95,
    label: 'Science in Administration',
  },
  {
    value: 96,
    label: 'Science in Archaeology',
  },
  {
    value: 97,
    label: 'Science in Biblical Archaeology',
  },
  {
    value: 98,
    label: 'Science in Bioinformatics',
  },
  {
    value: 99,
    label: 'Science in Computer Science',
  },
  {
    value: 100,
    label: 'Science in Counselling',
  },
  {
    value: 101,
    label: 'Science in Cyber Security',
  },
  {
    value: 102,
    label: 'Science in Development Administration',
  },
  {
    value: 103,
    label: 'Science in Engineering',
  },
  {
    value: 104,
    label: 'Science in Finance',
  },
  {
    value: 105,
    label: 'Science in Health Informatics',
  },
  {
    value: 106,
    label: 'Science in Human Resource Development',
  },
  {
    value: 107,
    label: 'Science in Information Assurance',
  },
  {
    value: 108,
    label: 'Science in Information Systems',
  },
  {
    value: 109,
    label: 'Science in Information Technology',
  },
  {
    value: 110,
    label: 'Science in Leadership',
  },
  {
    value: 111,
    label: 'Science in Management',
  },
  {
    value: 112,
    label: 'Science in Nursing',
  },
  {
    value: 113,
    label: 'Science in Project Management',
  },
  {
    value: 114,
    label: 'Science in Supply Chain Management',
  },
  {
    value: 115,
    label: 'Science in Taxation',
  },
  {
    value: 116,
    label: 'Science in Teaching',
  },
  {
    value: 117,
    label: 'Social Science',
  },
  {
    value: 118,
    label: 'Social Work',
  },
  {
    value: 119,
    label: 'Social Work',
  },
  {
    value: 120,
    label: 'Strategic Studies',
  },
  {
    value: 121,
    label: 'Studies',
  },
  {
    value: 122,
    label: 'Studies in Law',
  },
  {
    value: 123,
    label: 'Surgery',
  },
  {
    value: 124,
    label: 'Taxation',
  },
  {
    value: 125,
    label: 'Technology',
  },
  {
    value: 126,
    label: 'Theological Studies',
  },
  {
    value: 127,
    label: 'Theology',
  },
  {
    value: 128,
    label: 'Urban Planning',
  },
  {
    value: 129,
    label: 'Veterinary Science',
  },
];

export const degrees = [
  {
    value: 1,
    label: 'Accounting',
  },
  {
    value: 2,
    label: 'Accounting Technician',
  },
  {
    value: 3,
    label: 'Actuarial Science*',
  },
  {
    value: 4,
    label: 'Adult Development & Aging/Gerontology',
  },
  {
    value: 5,
    label: 'Advertising',
  },
  {
    value: 6,
    label: 'Aeronautical/Aerospace Engineering Technologies',
  },
  {
    value: 7,
    label: 'Aerospace/Aeronautical Engineering',
  },
  {
    value: 8,
    label: 'African American Studies',
  },
  {
    value: 9,
    label: 'Agribusiness Operations',
  },
  {
    value: 10,
    label: 'Agricultural Business & Management',
  },
  {
    value: 11,
    label: 'Agricultural Economics',
  },
  {
    value: 12,
    label: 'Agricultural Education',
  },
  {
    value: 13,
    label: 'Agricultural Mechanization',
  },
  {
    value: 14,
    label: 'Agricultural Production',
  },
  {
    value: 15,
    label: 'Agricultural/Bioengineering',
  },
  {
    value: 16,
    label: 'Agriculture, General',
  },
  {
    value: 17,
    label: 'Agronomy & Crop Science',
  },
  {
    value: 18,
    label: 'Aircraft Mechanics/Technology',
  },
  {
    value: 19,
    label: 'Aircraft Piloting & Navigation',
  },
  {
    value: 20,
    label: 'Alcohol/Drug Abuse Counseling',
  },
  {
    value: 21,
    label: 'American Indian/Native American Studies',
  },
  {
    value: 22,
    label: 'American/English Literature',
  },
  {
    value: 23,
    label: 'Animal Sciences',
  },
  {
    value: 24,
    label: 'Anthropology',
  },
  {
    value: 25,
    label: 'Applied Mathematics',
  },
  {
    value: 26,
    label: 'Architectural Drafting/CAD Technology',
  },
  {
    value: 27,
    label: 'Architectural Engineering',
  },
  {
    value: 28,
    label: 'Architectural Engineering Technology',
  },
  {
    value: 29,
    label: 'Architectural Environmental Design',
  },
  {
    value: 30,
    label: 'Architecture, General',
  },
  {
    value: 31,
    label: 'Area Studies, General (e.g., African, Middle Eastern)',
  },
  {
    value: 32,
    label: 'Art Education',
  },
  {
    value: 33,
    label: 'Art History, Criticism & Conservation',
  },
  {
    value: 34,
    label: 'Art, General',
  },
  {
    value: 35,
    label: 'Asian Area Studies',
  },
  {
    value: 36,
    label: 'Asian Languages & Literatures',
  },
  {
    value: 37,
    label: 'Astronomy',
  },
  {
    value: 38,
    label: 'Athletic Training',
  },
  {
    value: 39,
    label: 'Atmospheric Sciences & Meteorology',
  },
  {
    value: 40,
    label: 'Autobody Repair/Technology',
  },
  {
    value: 41,
    label: 'Automotive Engineering Technology',
  },
  {
    value: 42,
    label: 'Automotive Mechanics/Technology',
  },
  {
    value: 43,
    label: 'Aviation & Airway Science, General',
  },
  {
    value: 44,
    label: 'Aviation Management & Operations',
  },
  {
    value: 45,
    label: 'Avionics Technology',
  },
  {
    value: 46,
    label: 'Banking & Financial Support Services',
  },
  {
    value: 47,
    label: 'Bible/Biblical Studies',
  },
  {
    value: 48,
    label: 'Biochemistry & Biophysics',
  },
  {
    value: 49,
    label: 'Biology, General',
  },
  {
    value: 50,
    label: 'Biomedical Engineering',
  },
  {
    value: 51,
    label: 'Business Administration & Management, General',
  },
  {
    value: 52,
    label: 'Business Education',
  },
  {
    value: 53,
    label: 'Business/Management Quantitative Methods, General',
  },
  {
    value: 54,
    label: 'Business/Managerial Economics',
  },
  {
    value: 55,
    label: 'Career & Technical Education',
  },
  {
    value: 56,
    label: 'Cell/Cellular Biology',
  },
  {
    value: 57,
    label: 'Chemical Engineering',
  },
  {
    value: 58,
    label: 'Chemistry',
  },
  {
    value: 59,
    label: 'Child Care Services Management',
  },
  {
    value: 60,
    label: 'Child Development',
  },
  {
    value: 61,
    label: 'Chiropractic (Pre-Chiropractic)',
  },
  {
    value: 62,
    label: 'Cinema/Film',
  },
  {
    value: 63,
    label: 'Cinematography/Film/Vide Production',
  },
  {
    value: 64,
    label: 'City/Urban/Regional Planning',
  },
  {
    value: 65,
    label: 'Civil Engineering',
  },
  {
    value: 66,
    label: 'Civil Engineering Technology',
  },
  {
    value: 67,
    label: 'Classical/Ancient Languages & Literatures',
  },
  {
    value: 68,
    label: 'Communication Disorder Services (e.g., Speech Pathology)',
  },
  {
    value: 69,
    label: 'Communications Technology, General',
  },
  {
    value: 70,
    label: 'Communications, General',
  },
  {
    value: 71,
    label: 'Community Organization & Advocacy',
  },
  {
    value: 72,
    label: 'Comparative Literature',
  },
  {
    value: 73,
    label: 'Computer & Information Sciences, General',
  },
  {
    value: 74,
    label: 'Computer Engineering',
  },
  {
    value: 75,
    label: 'Computer Engineering Technology',
  },
  {
    value: 76,
    label: 'Computer Networking/Telecommunications',
  },
  {
    value: 77,
    label: 'Computer Science & Programming',
  },
  {
    value: 78,
    label: 'Computer Software & Media Applications',
  },
  {
    value: 79,
    label: 'Computer System Administration',
  },
  {
    value: 80,
    label: 'Construction Engineering/Management',
  },
  {
    value: 81,
    label: 'Construction Trades (e.g., carpentry, plumbing, electrical)',
  },
  {
    value: 82,
    label: 'Construction/Building Technology',
  },
  {
    value: 83,
    label: 'Consumer & Family Economics',
  },
  {
    value: 84,
    label: 'Corrections',
  },
  {
    value: 85,
    label: 'Cosmetology/Hairstyling*',
  },
  {
    value: 86,
    label: 'Counseling & Student Services',
  },
  {
    value: 87,
    label: 'Court Reporting*',
  },
  {
    value: 88,
    label: 'Creative Writing',
  },
  {
    value: 89,
    label: 'Criminal Justice',
  },
  {
    value: 90,
    label: 'Criminology',
  },
  {
    value: 91,
    label: 'Culinary Arts/Chef Training',
  },
  {
    value: 92,
    label: 'Curriculum & Instruction',
  },
  {
    value: 93,
    label: 'Dance',
  },
  {
    value: 94,
    label: 'Data Management Technology',
  },
  {
    value: 95,
    label: 'Dental Assisting',
  },
  {
    value: 96,
    label: 'Dental Hygiene',
  },
  {
    value: 97,
    label: 'Dentistry (Pre-Dentistry)',
  },
  {
    value: 98,
    label: 'Design & Visual Communications, General',
  },
  {
    value: 99,
    label: 'Diesel Mechanics/Technology',
  },
  {
    value: 100,
    label: 'Digital Communications/Media',
  },
  {
    value: 101,
    label: 'Divinity/Ministry',
  },
  {
    value: 102,
    label: 'Drafting/CAD Technology, General',
  },
  {
    value: 103,
    label: 'Early Childhood Education',
  },
  {
    value: 104,
    label: 'Ecology',
  },
  {
    value: 105,
    label: 'Economics',
  },
  {
    value: 106,
    label: 'Educational Administration',
  },
  {
    value: 107,
    label: 'Electrical, Electronics & Communications Engineering',
  },
  {
    value: 108,
    label: 'Electrical, Electronics Engineering Technologies',
  },
  {
    value: 109,
    label: 'Electrical/Electronics Equip Installation & Repair',
  },
  {
    value: 110,
    label: 'Electromechanical/Biomedical Engineering Technologies',
  },
  {
    value: 111,
    label: 'Elementary Education',
  },
  {
    value: 112,
    label: 'Emergency Medical Technology',
  },
  {
    value: 113,
    label: 'Engineering (Pre-Engineering), General',
  },
  {
    value: 114,
    label: 'Engineering Technology, General',
  },
  {
    value: 115,
    label: 'English Language & Literature, General',
  },
  {
    value: 116,
    label: 'English/Language Arts Education',
  },
  {
    value: 117,
    label: 'English-as-a-Second-Language Education',
  },
  {
    value: 118,
    label: 'Environmental Control Technologies',
  },
  {
    value: 119,
    label: 'Environmental Health Engineering',
  },
  {
    value: 120,
    label: 'Environmental Science',
  },
  {
    value: 121,
    label: 'Ethnic & Minority Studies, General',
  },
  {
    value: 122,
    label: 'European Area Studies',
  },
  {
    value: 123,
    label: 'Exercise Science/Physiology/Kinesiology',
  },
  {
    value: 124,
    label: 'Family & Consumer Sciences, General',
  },
  {
    value: 125,
    label: 'Fashion Merchandising',
  },
  {
    value: 126,
    label: 'Fashion/Apparel Design',
  },
  {
    value: 127,
    label: 'Finance, General',
  },
  {
    value: 128,
    label: 'Financial Planning & Services',
  },
  {
    value: 129,
    label: 'Fine/Studio Arts',
  },
  {
    value: 130,
    label: 'Fire Protection & Safety Technology',
  },
  {
    value: 131,
    label: 'Food & Nutrition',
  },
  {
    value: 132,
    label: 'Food Sciences & Technology',
  },
  {
    value: 133,
    label: 'Foreign Languages Education',
  },
  {
    value: 134,
    label: 'Foreign Languages/Literatures, General',
  },
  {
    value: 135,
    label: 'Forestry',
  },
  {
    value: 136,
    label: 'French Language & Literature',
  },
  {
    value: 137,
    label: 'Funeral Services & Mortuary Science',
  },
  {
    value: 138,
    label: 'Genetics',
  },
  {
    value: 139,
    label: 'Geography',
  },
  {
    value: 140,
    label: 'Geological & Earth Sciences',
  },
  {
    value: 141,
    label: 'German Language & Literature',
  },
  {
    value: 142,
    label: 'Graphic & Printing Equipment Operation*',
  },
  {
    value: 143,
    label: 'Graphic Design',
  },
  {
    value: 144,
    label: 'Health & Physical Education/Fitness',
  },
  {
    value: 145,
    label: 'Health Education',
  },
  {
    value: 146,
    label: 'Health Services Administration,General',
  },
  {
    value: 147,
    label: 'Health/Medical Technology, General',
  },
  {
    value: 148,
    label: 'Health-Related Professions & Services, General*',
  },
  {
    value: 149,
    label: 'Heating/Air Cond/Refrig Install/Repair',
  },
  {
    value: 150,
    label: 'History',
  },
  {
    value: 151,
    label: 'Horticulture Operations & Management',
  },
  {
    value: 152,
    label: 'Horticulture Science',
  },
  {
    value: 153,
    label: 'Hospital/Facilities Administration',
  },
  {
    value: 154,
    label: 'Hotel/Motel Management',
  },
  {
    value: 155,
    label: 'Human Resources Development/Training',
  },
  {
    value: 156,
    label: 'Human Resources Management',
  },
  {
    value: 157,
    label: 'Industrial Design',
  },
  {
    value: 158,
    label: 'Industrial Engineering',
  },
  {
    value: 159,
    label: 'Industrial Production Technologies',
  },
  {
    value: 160,
    label: 'Information Science',
  },
  {
    value: 161,
    label: 'Insurance & Risk Management',
  },
  {
    value: 162,
    label: 'Interior Architecture',
  },
  {
    value: 163,
    label: 'Interior Design',
  },
  {
    value: 164,
    label: 'International Business Management',
  },
  {
    value: 165,
    label: 'International Relations & Affairs',
  },
  {
    value: 166,
    label: 'Investments & Securities',
  },
  {
    value: 167,
    label: 'Journalism, Broadcast',
  },
  {
    value: 168,
    label: 'Journalism, Print',
  },
  {
    value: 169,
    label: 'Junior High/Middle School Education',
  },
  {
    value: 170,
    label: 'Labor/Industrial Relations',
  },
  {
    value: 171,
    label: 'Landscape Architecture',
  },
  {
    value: 172,
    label: 'Latin American Area Studies',
  },
  {
    value: 173,
    label: 'Latino/Chicano Studies',
  },
  {
    value: 174,
    label: 'Law (Pre-Law)',
  },
  {
    value: 175,
    label: 'Law Enforcement',
  },
  {
    value: 176,
    label: 'Legal Administrative Assisting/Secretarial*',
  },
  {
    value: 177,
    label: 'Legal Studies, General*',
  },
  {
    value: 178,
    label: 'Liberal Arts & General Studies*',
  },
  {
    value: 179,
    label: 'Library Science',
  },
  {
    value: 180,
    label: 'Linguistics',
  },
  {
    value: 181,
    label: 'Logistics & Materials Management',
  },
  {
    value: 182,
    label: 'Machine Tool Technology',
  },
  {
    value: 183,
    label: 'Management Information Systems',
  },
  {
    value: 184,
    label: 'Marine/Aquatic Biology',
  },
  {
    value: 185,
    label: 'Marketing Management & Research',
  },
  {
    value: 186,
    label: 'Mass Communications',
  },
  {
    value: 187,
    label: 'Massage Therapy',
  },
  {
    value: 188,
    label: 'Mathematics Education',
  },
  {
    value: 189,
    label: 'Mathematics, General',
  },
  {
    value: 190,
    label: 'Mechanical Drafting/CAD Technology',
  },
  {
    value: 191,
    label: 'Mechanical Engineering',
  },
  {
    value: 192,
    label: 'Mechanical Engineering Technology',
  },
  {
    value: 193,
    label: 'Mechanics & Repairers, General',
  },
  {
    value: 194,
    label: 'Medical Assisting',
  },
  {
    value: 195,
    label: 'Medical Laboratory Technology',
  },
  {
    value: 196,
    label: 'Medical Office/Secretarial',
  },
  {
    value: 197,
    label: 'Medical Radiologic Technology',
  },
  {
    value: 198,
    label: 'Medical Records',
  },
  {
    value: 199,
    label: 'Medical/Clinical Assisting, General',
  },
  {
    value: 200,
    label: 'Medicine (Pre-Medicine)',
  },
  {
    value: 201,
    label: 'Mental Health Counseling',
  },
  {
    value: 202,
    label: 'Microbiology & Immunology',
  },
  {
    value: 203,
    label: 'Middle Eastern Languages & Literatures',
  },
  {
    value: 204,
    label: 'Military Technologies*',
  },
  {
    value: 205,
    label: 'Multi/Interdisciplinary Studies*',
  },
  {
    value: 206,
    label: 'Multimedia/Animation/Special Effects',
  },
  {
    value: 207,
    label: 'Music Education',
  },
  {
    value: 208,
    label: 'Music, General',
  },
  {
    value: 209,
    label: 'Music, Performance',
  },
  {
    value: 210,
    label: 'Music, Theory & Composition',
  },
  {
    value: 211,
    label: 'Natural Resources Conservation, General',
  },
  {
    value: 212,
    label: 'Natural Resources Management',
  },
  {
    value: 213,
    label: 'North American Area Studies',
  },
  {
    value: 214,
    label: 'Nuclear Engineering',
  },
  {
    value: 215,
    label: 'Nuclear Medicine Technology',
  },
  {
    value: 216,
    label: 'Nursing, Practical/Vocational (LPN)',
  },
  {
    value: 217,
    label: 'Nursing, Registered (BS/RN)',
  },
  {
    value: 218,
    label: 'Occupational Therapy',
  },
  {
    value: 219,
    label: 'Occupational Therapy Assisting',
  },
  {
    value: 220,
    label: 'Office Supervision & Management',
  },
  {
    value: 221,
    label: 'Operations Management & Supervision',
  },
  {
    value: 222,
    label: 'Optometry (Pre-Optometry)',
  },
  {
    value: 223,
    label: 'Organizational Behavior',
  },
  {
    value: 224,
    label: 'Osteopathic Medicine',
  },
  {
    value: 226,
    label: 'Paralegal/Legal Assistant',
  },
  {
    value: 227,
    label: 'Parks, Recreation, & Leisure, General',
  },
  {
    value: 228,
    label: 'Parks/Rec/Leisure Facilities Management',
  },
  {
    value: 229,
    label: 'Personal Services, General*',
  },
  {
    value: 230,
    label: 'Pharmacy (Pre-Pharmacy)',
  },
  {
    value: 231,
    label: 'Philosophy',
  },
  {
    value: 232,
    label: 'Photography',
  },
  {
    value: 233,
    label: 'Physical Education & Coaching',
  },
  {
    value: 234,
    label: 'Physical Sciences, General',
  },
  {
    value: 235,
    label: 'Physical Therapy (Pre-Physical Therapy)',
  },
  {
    value: 236,
    label: 'Physical Therapy Assisting',
  },
  {
    value: 237,
    label: 'Physician Assisting',
  },
  {
    value: 238,
    label: 'Physics',
  },
  {
    value: 239,
    label: 'Political Science & Government',
  },
  {
    value: 240,
    label: 'Postsecondary Education',
  },
  {
    value: 241,
    label: 'Precision Production Trades, General',
  },
  {
    value: 242,
    label: 'Protective Services, General',
  },
  {
    value: 243,
    label: 'Psychiatric/Mental Health Technician',
  },
  {
    value: 244,
    label: 'Psychology, Clinical & Counseling',
  },
  {
    value: 245,
    label: 'Psychology, General',
  },
  {
    value: 246,
    label: 'Public Administration',
  },
  {
    value: 247,
    label: 'Public Administration & Services, General',
  },
  {
    value: 248,
    label: 'Public Affairs & Public Policy Analysis',
  },
  {
    value: 249,
    label: 'Public Health',
  },
  {
    value: 250,
    label: 'Public Relations & Organizational Communication',
  },
  {
    value: 251,
    label: 'Public Speaking',
  },
  {
    value: 252,
    label: 'Purchasing/Procurement/Contracts Management',
  },
  {
    value: 253,
    label: 'Quality Control & Safety Technologies',
  },
  {
    value: 254,
    label: 'Radio & Television Broadcasting',
  },
  {
    value: 255,
    label: 'Radio & Television Broadcasting Technology*',
  },
  {
    value: 256,
    label: 'Real Estate',
  },
  {
    value: 257,
    label: 'Rehabilitation Therapy',
  },
  {
    value: 258,
    label: 'Religion',
  },
  {
    value: 259,
    label: 'Religious Education',
  },
  {
    value: 260,
    label: 'Respiratory Therapy Technology',
  },
  {
    value: 261,
    label: 'Restaurant/Food Services Management',
  },
  {
    value: 262,
    label: 'Sales, Merchandising, & Marketing, General',
  },
  {
    value: 263,
    label: 'Science Education',
  },
  {
    value: 264,
    label: 'Secondary Education',
  },
  {
    value: 265,
    label: 'Secretarial Studies & Office Administration',
  },
  {
    value: 266,
    label: 'Small Business Management/Operations',
  },
  {
    value: 267,
    label: 'Social Sciences, General',
  },
  {
    value: 268,
    label: 'Social Studies/Sciences Education',
  },
  {
    value: 269,
    label: 'Social Work',
  },
  {
    value: 270,
    label: 'Sociology',
  },
  {
    value: 271,
    label: 'Spanish Language & Literature',
  },
  {
    value: 272,
    label: 'Special Education',
  },
  {
    value: 273,
    label: 'Sport & Fitness Administration/Management',
  },
  {
    value: 274,
    label: 'Statistics',
  },
  {
    value: 275,
    label: 'Surgical Technology',
  },
  {
    value: 276,
    label: 'Surveying Technology',
  },
  {
    value: 277,
    label: 'Teacher Assisting/Aide Education',
  },
  {
    value: 278,
    label: 'Teacher Education, General',
  },
  {
    value: 279,
    label: 'Teacher Education, Subject-Specific*',
  },
  {
    value: 280,
    label: 'Textile & Apparel',
  },
  {
    value: 281,
    label: 'Theatre Arts/Drama',
  },
  {
    value: 282,
    label: 'Theology, General',
  },
  {
    value: 283,
    label: 'Therapy & Rehabilitation, General',
  },
  {
    value: 284,
    label: 'Tourism & Travel Marketing',
  },
  {
    value: 285,
    label: 'Transportation & Materials Moving (e.g., air, ground, & marine)',
  },
  {
    value: 286,
    label: 'Travel/Tourism Management',
  },
  {
    value: 287,
    label: 'Urban Studies/Urban Affairs',
  },
  {
    value: 288,
    label: 'Veterinarian Assisting/Technology',
  },
  {
    value: 289,
    label: 'Veterinary Medicine (Pre-Veterinarian)',
  },
  {
    value: 290,
    label: 'Vocational Rehabilitation Counseling',
  },
  {
    value: 291,
    label: 'Webpage Design',
  },
  {
    value: 292,
    label: 'Welding Technology',
  },
  {
    value: 293,
    label: 'Wildlife & Wildlands Management',
  },
  {
    value: 294,
    label: 'Women’s Studies',
  },
  {
    value: 295,
    label: 'Zoology',
  },
];

export const doctorates = [
  {
    value: 1,
    label: 'Acupuncture',
  },
  {
    value: 2,
    label: 'Athletic Training',
  },
  {
    value: 3,
    label: 'Audiology',
  },
  {
    value: 4,
    label: 'Behavioral Health',
  },
  {
    value: 5,
    label: 'Chiropractor',
  },
  {
    value: 6,
    label: 'Clinical Laboratory Science',
  },
  {
    value: 7,
    label: 'Computer Science',
  },
  {
    value: 8,
    label: 'Counseling',
  },
  {
    value: 9,
    label: 'Dentistry',
  },
  {
    value: 10,
    label: 'Doctor of Applied Science',
  },
  {
    value: 11,
    label: 'Doctor of Architecture',
  },
  {
    value: 12,
    label: 'Doctor of Arts',
  },
  {
    value: 13,
    label: 'Doctor of Arts in Teaching',
  },
  {
    value: 14,
    label: 'Doctor of Business Administration',
  },
  {
    value: 15,
    label: 'Doctor of Canon Law',
  },
  {
    value: 16,
    label: 'Doctor of Chemistry',
  },
  {
    value: 17,
    label: 'Doctor of Church Music',
  },
  {
    value: 18,
    label: 'Doctor of Comparative/Civil Law',
  },
  {
    value: 19,
    label: 'Doctor of Criminal Justice',
  },
  {
    value: 20,
    label: 'Doctor of Criminology',
  },
  {
    value: 21,
    label: 'Doctor of Design',
  },
  {
    value: 22,
    label: 'Doctor of Education',
  },
  {
    value: 23,
    label: 'Doctor of Engineering',
  },
  {
    value: 24,
    label: 'Doctor of Environment',
  },
  {
    value: 25,
    label: 'Doctor of Fine Arts',
  },
  {
    value: 26,
    label: 'Doctor of Forestry',
  },
  {
    value: 27,
    label: 'Doctor of Geological Science',
  },
  {
    value: 28,
    label: 'Doctor of Health and Safety',
  },
  {
    value: 29,
    label: 'Doctor of Hebrew Letters/Literature',
  },
  {
    value: 30,
    label: 'Doctor of Hebrew Studies',
  },
  {
    value: 31,
    label: 'Doctor of Industrial Technology',
  },
  {
    value: 32,
    label: 'Doctor of Juridical Science',
  },
  {
    value: 33,
    label: 'Doctor of Juristic Science',
  },
  {
    value: 34,
    label: 'Doctor of Library Science',
  },
  {
    value: 35,
    label: 'Doctor of Medical Science',
  },
  {
    value: 36,
    label: 'Doctor of Missiology',
  },
  {
    value: 37,
    label: 'Doctor of Modern Languages',
  },
  {
    value: 38,
    label: 'Doctor of Music',
  },
  {
    value: 39,
    label: 'Doctor of Music Education',
  },
  {
    value: 40,
    label: 'Doctor of Music Ministry',
  },
  {
    value: 41,
    label: 'Doctor of Musical Arts',
  },
  {
    value: 42,
    label: 'Doctor of Nursing Science',
  },
  {
    value: 43,
    label: 'Doctor of Philosophy',
  },
  {
    value: 44,
    label: 'Doctor of Physical Education',
  },
  {
    value: 45,
    label: 'Doctor of Professional Studies',
  },
  {
    value: 46,
    label: 'Doctor of Public Administration',
  },
  {
    value: 47,
    label: 'Doctor of Public Health',
  },
  {
    value: 48,
    label: 'Doctor of Recreation',
  },
  {
    value: 49,
    label: 'Doctor of Rehabilitation',
  },
  {
    value: 50,
    label: 'Doctor of Religious Education',
  },
  {
    value: 51,
    label: 'Doctor of Sacred Music',
  },
  {
    value: 52,
    label: 'Doctor of Sacred Theology',
  },
  {
    value: 53,
    label: 'Doctor of Science',
  },
  {
    value: 54,
    label: 'Doctor of Science and Hygiene',
  },
  {
    value: 55,
    label: 'Doctor of Science in Dentistry',
  },
  {
    value: 56,
    label: 'Doctor of Science in Veterinary Medicine',
  },
  {
    value: 57,
    label: 'Doctor of Science of Law',
  },
  {
    value: 58,
    label: 'Doctor of Social Science',
  },
  {
    value: 59,
    label: 'Doctor of Social Work',
  },
  {
    value: 60,
    label: 'Doctor of Theology',
  },
  {
    value: 61,
    label: 'Doctoral Degree',
  },
  {
    value: 62,
    label: 'Economic Development',
  },
  {
    value: 63,
    label: 'Health Administration',
  },
  {
    value: 64,
    label: 'Health Science',
  },
  {
    value: 65,
    label: 'Law',
  },
  {
    value: 66,
    label: 'Management',
  },
  {
    value: 67,
    label: 'Medical Physics',
  },
  {
    value: 68,
    label: 'Medicine',
  },
  {
    value: 69,
    label: 'Ministry (clergy)',
  },
  {
    value: 70,
    label: 'Music',
  },
  {
    value: 71,
    label: 'Naturopathy',
  },
  {
    value: 72,
    label: 'Nursing',
  },
  {
    value: 73,
    label: 'Occupational Therapy',
  },
  {
    value: 74,
    label: 'Optometry',
  },
  {
    value: 75,
    label: 'Osteopathic Medicine',
  },
  {
    value: 76,
    label: 'Pharmacy',
  },
  {
    value: 77,
    label: 'Physical Therapy',
  },
  {
    value: 78,
    label: 'Podiatry',
  },
  {
    value: 79,
    label: 'Policy and Law',
  },
  {
    value: 80,
    label: 'Psychology',
  },
  {
    value: 81,
    label: 'Social Worker',
  },
  {
    value: 82,
    label: 'Speech-Language Pathology',
  },
  {
    value: 83,
    label: 'Veterinary medicine',
  },
];

export const debt = [
  { 'value': 1, 'label': '$0 - 10,000' },
  { 'value': 2, 'label': '$10,001 - $25,000' },
  { 'value': 3, 'label': '$25,001 – $50,000' },
  { 'value': 4, 'label': '$50,001 - $75,000' },
  { 'value': 5, 'label': '$75,001 - $100,000' },
  { 'value': 6, 'label': '$100,001 - $150,000' },
  { 'value': 7, 'label': '$150,001 - $200,000' },
  { 'value': 8, 'label': '$200,001 - $250,000' },
  { 'value': 9, 'label': '$250,000+' },
]
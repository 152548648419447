import { useEffect, useState } from 'react';

export const useTimer = (startTime: number = 60) => {
  const [ time, setTime ] = useState(startTime);
  const [ allowResend, setAllowResend ] = useState(false);

  useEffect(() => {
    const current = setInterval(() => {
      setTime(_time => _time - 1);
    }, 1000);
    return () => clearInterval(current);
  }, []);

  // if time is zero then allow resend button
  useEffect(() => {
    time === 0 && setAllowResend(true);
  }, [ time ]);

  return {
    allowResend,
    time,
  };
};

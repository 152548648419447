export const gpa = [
  { 'value': 1, 'label': '0.0 - 1.0' },
  { 'value': 2, 'label': '1.01 - 1.50' },
  { 'value': 3, 'label': '1.51 - 2.0' },
  { 'value': 4, 'label': '2.01 - 2.33' },
  { 'value': 5, 'label': '2.34 - 2.66' },
  { 'value': 6, 'label': '2.67 - 3.0' },
  { 'value': 7, 'label': '3.01 - 3.20' },
  { 'value': 8, 'label': '3.21 - 3.40' },
  { 'value': 9, 'label': '3.41 - 3.60' },
  { 'value': 10, 'label': '3.61 - 3.80' },
  { 'value': 11, 'label': '3.81 - 4.0' },
  { 'value': 12, 'label': '4.0+' },
]
export const loanServicer =[
  { 'value': 1, 'label': 'Fedloans' },
  { 'value': 2, 'label': 'Nelnet' },
  { 'value': 3, 'label': 'Navient' },
  { 'value': 4, 'label': 'MOHELA' },
  { 'value': 5, 'label': 'OSLA' },
  { 'value': 6, 'label': 'Great Lakes' },
  { 'value': 7, 'label': 'Granite State' },
  { 'value': 8, 'label': 'Maximus' },
  { 'value': 9, 'label': 'SOFI' },
  { 'value': 10, 'label': 'Discover' },
  { 'value': 11, 'label': 'Sallie Mae' },
  { 'value': 12, 'label': 'First Trust Bank' },
  { 'value': 13, 'label': 'One American Bank' },
  { 'value': 14, 'label': 'Southeast Bank' },
  { 'value': 15, 'label': 'Bank of Lake Mills' },
  { 'value': 16, 'label': 'Richland State Bank' },
  { 'value': 17, 'label': 'Citizens Bank' },
  { 'value': 18, 'label': 'PNC Bank' },
  { 'value': 19, 'label': 'Navy Federal Credit Union' },
  { 'value': 20, 'label': 'OTHER' },
]

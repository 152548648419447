export const Experience = [
  { 'value': 1, 'label': '0 - 6 months' },
  { 'value': 2, 'label': '7 - 12 months' },
  { 'value': 3, 'label': '1 - 2 years' },
  { 'value': 4, 'label': '3 - 5 years' },
  { 'value': 5, 'label': '5 - 10 years' },
  { 'value': 6, 'label': '10+ years' },
]

export const experience = [
  { 'value': 1, 'label': '0 years' },
  { 'value': 2, 'label': '1 - 2 years' },
  { 'value': 3, 'label': '3 - 5 years' },
  { 'value': 4, 'label': '5 - 10 years' },
  { 'value': 5, 'label': '10+ years' },
]
import React, { FC } from 'react';
import { Cascader } from 'antd';
import { dropDownOptions } from '../../../assets/data';

type Props = {
  dropdownName: string;
  placeholder?: string;
  displayRender?: any;
  disabled?: boolean;
  [key: string]: any;
};

const AsyncCascader: FC<Props> = ({ dropdownName, ...rest }) => {
  return (
    <Cascader
      {...rest}
      options={Array.isArray(dropDownOptions[dropdownName]) ? dropDownOptions[dropdownName] : []}
      disabled={rest.disabled}
    />
  );
};
AsyncCascader.defaultProps = {
  disabled: false,
};

export default AsyncCascader;
